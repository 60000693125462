import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";

import * as actions from "../../../actions";

const fallBack = require("../../../images/fallbackAvatar.png");

class GamedevApplicationActSlider extends React.Component {
    constructor(props) {
        super(props);

        this.settings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            // slidesToScroll: 3,
            vertical: false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        //   vertical: false,
                        //   verticalSwiping: false,
                    }
                }
            ],
            // nextArrow: <SampleNextArrow />,
            // prevArrow: <SamplePrevArrow />
        }

        this.state = { messages: "" }
    }

    edit = async (i) => {
        await this.setState({
            editing: i
        });

        window.scrollTo(0, 0);
    }

    

    render() {
        if(this.state.editing) {
            this.props.fetchUser();
            return(
                <Redirect push to={`${process.env.REACT_APP_GAMEDEV_EDIT_APP}/${this.state.editing}`}/>
            );
        }

        return(
            <>
            <div className="row">
                {Array.isArray(this.props.info.applications) && this.props.info.applications.length > 0 
                    ? this.props.info.applications.map(i => {
                    return(
                        <div key={i.name} className="col-12 col-md-6 ">
                            <div key={i.imageID}
                            className="position-relative gamedevact-application-card"
                            style={i.accepted ? { cursor: "pointer" } : {}}
                            >
                                <div className="gamedevact-image-container"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.edit(i.name)}
                                >
                                    <img className="gamedevact-image-application-website"
                                    src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.name}&rand=${Date.now()}`}
                                    onError={i => i.target.src = fallBack}
                                    alt="Application"
                                    />
                                </div>
                                <div className="gamedevact-textblock-application w-100">
                                    <div className="gamedevact-text-bg-application"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.edit(i.name)}>
                                        {i.display_name ? i.display_name : i.name}
                                    </div>

                                    <div className="gamedevact-description-application scroll-25"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.edit(i.name)}>
                                        <div className="gamedevact-text-sm"
                                        style={{whiteSpace: 'pre-line'}}>
                                            {i.description}
                                        </div>

                                        <div className="gamedevact-link">
                                            {i.website}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }) : (
                    <h5 className="mt-4 mx-2">
                    </h5>
                )}
            
            </div>
            </>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUser: bindActionCreators(actions.fetchUser, dispatch),
        getGames: bindActionCreators(actions.getGames, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        info: state.info,
        ual: state.ual
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GamedevApplicationActSlider);
