import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import ReactPlayer from 'react-player';
import EosEcc from 'eosjs-ecc';
import {Modal} from 'react-bootstrap';
import {ClipLoader} from 'react-spinners';

import useWindowSize from '../../../helpers/useWindowSize';
import QUDOServer from '../../../helpers/QUDOServerConnection';

import keyIcon from '../../../images/WalletMigrationIcons/KeySetUp.png';

import * as MK from '../../../helpers/metakeep';

import './walletMigration.scss';

export default function WalletMigration(props) {
    const windowSize = useWindowSize();
    const isMobile = (windowSize && windowSize.width < 768);

	const info = useSelector(state => state.info);

    //const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState('introduction');

    const [newPublicKey, setNewPublicKey] = useState('');
    const [newPrivateKey, setNewPrivateKey] = useState('');

    const [password, setPassword] = useState('');
    const [publicKey, setPublicKey] = useState('');

    const [response, setResponse] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        EosEcc.initialize();
    }, []);

    /*
    useEffect(() => {
        if(step === 'introduction') {
            setNewPublicKey('');
            setNewPrivateKey('');
        }
    }, [step]);
    */

    async function generateNewKeys() {
        const privateKey = await EosEcc.randomKey();
        const publicKey = await EosEcc.privateToPublic(privateKey);

        setNewPublicKey(publicKey);
        setNewPrivateKey(privateKey);
    }

	function CopyToClipboard(value) {
		var tempInput = document.createElement("input");
		tempInput.value = value;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand("copy");
		document.body.removeChild(tempInput);

		//setCopied(true);
		//setTimeout(() => setCopied(false), 3000);
	}

    function sendRequest() {
        setLoading(true);

        if(process.env.REACT_APP_METAKEEP_RELEASED === 'true' || info.email === global.testAccEmail){
            const account = 'eosio';
            const actions = [];
            actions.push({
                "account": account,
                "name": 'updateauth',
                "authorization": [{
                    "actor": info.account_name,
                    "permission": "owner"
                }],
                "data": {
                    "account": info.account_name,
                    "permission": 'active',
                    "parent": 'owner',
                    "auth": {
                        "threshold": 1,
                        "keys": [
                            {
                                "key": newPublicKey,
                                "weight": 1
                            }
                        ],
                        "accounts": [],
                        "waits": []
                    }
                }
            });
            actions.push({
                "account": account,
                "name": 'updateauth',
                "authorization": [{
                    "actor": info.account_name,
                    "permission": 'owner'
                }],
                "data": {
                    "account": info.account_name,
                    "permission": 'owner',
                    "parent": '',
                    "auth": {
                        "threshold": 1,
                        "keys": [
                            {
                                "key": newPublicKey,
                                "weight": 1
                            }
                        ],
                        "accounts": [],
                        "waits": []
                    }
                }
            })

            MK.signTransaction(info.email, actions, {broadcast: true})
            .then((signResponse) => {
                console.log("sign successful", signResponse);
                setSuccess(true);
                setResponse('Success! From now on, you must sign your transactions by using one of the supported wallets.');
                QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/checkqudomanaged`,{withCredentials: true})
            })
            .catch((error) => {
                console.log("Metakeep send error on account", MK.getEOS(), error);
                setSuccess(false);
                setResponse(error.errorMessage);
            })
            .finally(() => {
                setLoading(false);
            });
        }

        else // Delete the next and this if after Metakeep

        {
        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/eos/migrate_account`, {
            password: password,
            publicKey: publicKey
        }, {
            withCredentials: true
        })
        .then(result => {
            setSuccess(true);
            //setResponse(result.data);
            setResponse('Success! From now on, you must sign your transactions by using one of the supported wallets.');
        })
        .catch(error => {
            setSuccess(false);
            setResponse(error.errorMessage);
        })
        .finally(() => {
            setLoading(false);
        });
        }
    }

    if(info && info.qudoManaged) {
        return(
            <Modal
                show={props.show}
                //onHide={props.hide}
            >
                <Modal.Header id='migration-header'>
                    <div className='w-100 text-center'>
                        Take control of your account
                    </div>
                </Modal.Header>

                <Modal.Body id='migration-body'>
                    <div className='my-3 text-center'>
                        TELOS Account Migration
                    </div>

                    <div className='text-center'>
                        <img
                            alt=''
                            src={keyIcon}
                            className='img-fluid'
                            style={{maxWidth: '100px'}}
                        />
                    </div>

                    {step === 'introduction' && (
                        <>
                            <div className='mt-3 mb-2 text-center'>
                                READ CAREFULLY
                            </div>

                            {/*}
                            <div style={{color: '#b2b2b2'}}>
                                This process will guide you through the migration of the ownership of your TELOS account.
                                After this process is completed, your TELOS account will no longer be managed by QUDO
                                and you'll be the one responsible for the management of the account's private key.
                                If for any reason you lose the private key, or your account somehow gets compromised,
                                we will not be able in any way to recover it since, by the end of this process,
                                the account's ownership will change and QUDO will lose access to it.
                                If you have any doubts or questions about this process, please contact the QUDO support team.
                            </div>
                            {*/}

                            {isMobile ? (
                                <div style={{color: '#b2b2b2'}}>
                                    To take full control of your Telos account, follow the next steps to complete the ownership migration 
                                    process. Remember, once the process is complete, QUDO will no longer manage your account, and you 
                                    will be solely responsible for its management and security. If you have any questions, contact our support team.
                                </div>
                            ) : (
                                <div style={{color: '#b2b2b2'}}>
                                    <div className='mb-1'>
                                        Congratulations on taking the first step toward gaining full control over your Telos account. The ownership 
                                        migration process will transfer account management from QUDO to you, giving you complete autonomy over 
                                        your Telos account. 
                                        It's important to note that once the migration is complete, you'll retain full control of your wallet, and QUDO 
                                        will no longer have access to it. This means that safeguarding your private key and ensuring its security 
                                        will be entirely your responsibility going forward.
                                    </div>
                                    <div className='mb-1'>
                                        If you encounter any challenges during the process or have any questions, our support team is available 
                                        to assist you. Please don't hesitate to reach out to us.
                                    </div>
                                </div>
                            )}

                            <div className='mt-4'>
                                <ReactPlayer
                                    url={`https://youtu.be/ek4edRq0miA?si=Ui8xWHuK5T93GXZP`}
                                    controls={true}
                                    light={true}
                                    width="100%"
                                />
                            </div>

                            <div className='mt-4'>
                                <Buttons
                                    back={() => props.hide()}
                                    next={() => setStep('keygen1')}
                                    allowNext={true}
                                />
                            </div>
                        </>
                    )}

                    {step === 'keygen1' && (
                        <>
                            <div
                                className='mt-4'
                                style={{color: '#b2b2b2'}}
                            >
                                {/*}
                                <div className='mb-1'>
                                    - Please insert the Public Key that should represent you as the owner of this TELOS account.
                                </div>
                                <div className='mb-1'>
                                    - Make sure that you have the Private Key that corresponds to this Public Key
                                    stored in a secure location and never share it with anyone!
                                </div>
                                <div className='mb-1'>
                                    - If you don't have a valid key pair, or don't know how to generate one, we've
                                    provided a key pair generator. This key pair generator works entirely on the
                                    frontend side of the application, so the generated private keys will never
                                    be sent over the internet. Make sure you store the keys in a secure location
                                    and never share the Private Key with anyone!
                                </div>
                                {*/}

                                {isMobile ? (
                                    <div>
                                        <div className='mb-1'>
                                            1) Generate a key pair using the provided generator or use a key pair you already own.
                                        </div>
                                        <div className='mb-1'>
                                            2) Insert your <b>Public Key</b> to represent your TELOS account ownership.
                                        </div>
                                        <div className='mb-1'>
                                            3) Store your <b>Private Key</b> in a secure location and never share it with anyone.
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <div className='mb-1'>
                                            1) <b>Generate a new KEY pair</b> if you don't have one already. If you already have a key pair, skip to step 2.
                                        </div>
                                        <div className='mb-1'>
                                            2) Insert your <b>Public Key</b> in the designated field to represent you as the owner of the TELOS account and link it to your QUDO account.
                                        </div>
                                        <div className='mb-1'>
                                            3) Ensure that you have the corresponding <b>Private Key</b> securely stored, and do not share it with anyone.
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='mt-4'>
                                <button
                                    type="button"
                                    className="std-button"
                                    style={{backgroundColor: '#fcd902'}}
                                    onClick={() => generateNewKeys()}
                                >
                                    <div
                                        className="text-center"
                                        style={{color: '#282725'}}
                                    >
                                        Generate new KEY pair
                                    </div>
                                </button>

                                {newPrivateKey && (
                                    <div className='mt-2'>
                                        <div
                                            className="text-center mb-2"
                                            style={{fontSize: '0.9rem', color: '#b2b2b2'}}
                                        >
                                            Private Key
                                        </div>
                                        <div
                                            className='keygen-area'
                                            onClick={() => CopyToClipboard(newPrivateKey)}
                                        >
                                            {newPrivateKey}
                                        </div>
                                    </div>
                                )}

                                {newPublicKey && (
                                    <div className='mt-3 mb-3'>
                                        <div
                                            className="text-center mb-2"
                                            style={{fontSize: '0.9rem', color: '#b2b2b2'}}
                                        >
                                            Public Key
                                        </div>
                                        <div
                                            className='keygen-area'
                                            onClick={() => CopyToClipboard(newPublicKey)}
                                        >
                                            {newPublicKey}
                                        </div>
                                    </div>
                                )}

                                {(newPrivateKey || newPublicKey) && (
                                    <div
                                        className='mb-2 text-center'
                                        style={{fontSize: '0.9rem', color: '#f44336'}}
                                    >
                                        Make sure you store the keys in a secure location and never share
                                        the Private Key with anyone!
                                    </div>
                                )}

                                {/*}
                                <input
                                    type='text'
                                    className='mt-2 migration-key-input'
                                    placeholder='Insert your new PUBLIC KEY'
                                    value={publicKey}
                                    onChange={(e) => setPublicKey(e.target.value)}
                                />
                                {*/}
                            </div>

                            <div className='mt-4'>
                                <Buttons
                                    back={() => setStep('introduction')}
                                    next={() => setStep('keygen2')}
                                    //allowNext={publicKey}
                                    allowNext={true}
                                />
                            </div>
                        </>
                    )}

                    {step === 'keygen2' && (
                        <>
                            <div
                                className='mt-4'
                                style={{color: '#b2b2b2'}}
                            >
                                {/*}
                                <div className='mb-1'>
                                    - Please insert the Public Key that should represent you as the owner of this TELOS account.
                                </div>
                                <div className='mb-1'>
                                    - Make sure that you have the Private Key that corresponds to this Public Key
                                    stored in a secure location and never share it with anyone!
                                </div>
                                <div className='mb-1'>
                                    - If you don't have a valid key pair, or don't know how to generate one, we've
                                    provided a key pair generator. This key pair generator works entirely on the
                                    frontend side of the application, so the generated private keys will never
                                    be sent over the internet. Make sure you store the keys in a secure location
                                    and never share the Private Key with anyone!
                                </div>
                                {*/}

                                <div>
                                    <b>IMPORTANT:</b> Make sure you have stored your new <b>PRIVATE KEY</b> in a secure location and <b>never share it with anyone!</b>
                                </div>
                            </div>

                            <div className='mt-4'>
                                {/*}
                                <button
                                    type="button"
                                    className="std-button"
                                    style={{backgroundColor: '#fcd902'}}
                                    onClick={() => generateNewKeys()}
                                >
                                    <div
                                        className="text-center"
                                        style={{color: '#282725'}}
                                    >
                                        Generate new KEY pair
                                    </div>
                                </button>

                                {newPrivateKey && (
                                    <div className='mt-2'>
                                        <div
                                            className="text-center mb-2"
                                            style={{fontSize: '0.9rem', color: '#b2b2b2'}}
                                        >
                                            Private Key
                                        </div>
                                        <div
                                            className='keygen-area'
                                            onClick={() => CopyToClipboard(newPrivateKey)}
                                        >
                                            {newPrivateKey}
                                        </div>
                                    </div>
                                )}

                                {newPublicKey && (
                                    <div className='mt-3 mb-3'>
                                        <div
                                            className="text-center mb-2"
                                            style={{fontSize: '0.9rem', color: '#b2b2b2'}}
                                        >
                                            Public Key
                                        </div>
                                        <div
                                            className='keygen-area'
                                            onClick={() => CopyToClipboard(newPublicKey)}
                                        >
                                            {newPublicKey}
                                        </div>
                                    </div>
                                )}

                                {(newPrivateKey || newPublicKey) && (
                                    <div
                                        className='mb-2 text-center'
                                        style={{fontSize: '0.9rem', color: '#f44336'}}
                                    >
                                        Make sure you store the keys in a secure location and never share
                                        the Private Key with anyone!
                                    </div>
                                )}
                                {*/}

                                <input
                                    type='text'
                                    className='mt-2 migration-key-input'
                                    placeholder='Insert your new PUBLIC KEY'
                                    value={publicKey}
                                    onChange={(e) => setPublicKey(e.target.value)}
                                />
                            </div>

                            <div className='mt-4'>
                                <Buttons
                                    back={() => setStep('keygen1')}
                                    next={() => setStep('confirmation')}
                                    allowNext={publicKey}
                                />
                            </div>
                        </>
                    )}

                    {step === 'confirmation' && (
                        <>
                            <div
                                className='mt-3'
                                style={{color: '#b2b2b2'}}
                            >
                                {/*}
                                <div className='mb-1'>
                                    - Make sure that you have the Private Key that corresponds to the Public Key you inserted
                                </div>
                                <div className='mb-1'>
                                    - Keep your keys stored in a secure location
                                </div>
                                <div className='mb-1'>
                                    - Never share your Private Key with anyone
                                </div>
                                {*/}

                                <div className='mb-1'>
                                    - Ensure you have the corresponding <b>Private Key</b> for the <b>Public Key</b> you inserted.
                                </div>
                                <div className='mb-1'>
                                    - Store your keys in a secure location and <b>never share your Private Key with anyone.</b>
                                </div>
                            </div>

                            <input
                                type='password'
                                className='mt-4 migration-password-input'
                                placeholder='Insert your QUDO PASSWORD to confirm'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            <div className='mt-4'>
                                <Buttons
                                    back={() => setStep('keygen2')}
                                    next={() => {
                                        sendRequest();
                                        setStep('result');
                                    }}
                                    allowNext={password}
                                />
                            </div>
                        </>
                    )}

                    {step === 'result' && (
                        <>
                            {loading ? (
                                <div className="migration-main-loading mt-3">
                                    <div className="migration-fof-loading">
                                        <h1 className="m-0 ml-3">
                                            <ClipLoader color="#fcd902"/>
                                        </h1>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div
                                        className='mt-4 text-center'
                                        style={success ? {color: '#00cc69'} : {color: '#f44336'}}
                                    >
                                        {response}
                                    </div>

                                    {success ? (
                                        <div className='row mt-4'>
                                            <div className='col-12'>
                                                <button
                                                    type="button"
                                                    className="std-button"
                                                    style={success ? {backgroundColor: '#00cc69'} : {backgroundColor: '#f44336'}}
                                                    onClick={() => {
                                                        props.hide();

                                                        if(success) {
                                                            window.location.reload();
                                                        }
                                                    }}
                                                >
                                                    <div className="text-center">
                                                        CLOSE
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='row mt-4'>
                                            <div className='col-12 col-sm-6'>
                                                <button
                                                    type="button"
                                                    className="std-button"
                                                    style={{backgroundColor: '#656565'}}
                                                    onClick={() => {
                                                        setPassword('');
                                                        setPublicKey('');
                                                        setNewPublicKey('');
                                                        setNewPrivateKey('');
                                                        setStep('keygen1');
                                                    }}
                                                >
                                                    <div
                                                        className="text-center"
                                                        style={{color: '#ededed'}}
                                                    >
                                                        BACK
                                                    </div>
                                                </button>
                                            </div>
                                            <div className='col-12 col-sm-6'>
                                                <button
                                                    type="button"
                                                    className="std-button"
                                                    style={{backgroundColor: '#f44336'}}
                                                    onClick={() => props.hide()}
                                                >
                                                    <div className="text-center">
                                                        CLOSE
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {success && (
                                        <div className='row mt-4'>
                                            <div className='col-12 mb-2'>
                                                To manage your TELOS account and QUDO tokens, you should select a wallet.
                                                Here's a video to help you with this process:
                                            </div>
                                            <div className='col-12'>
                                                <ReactPlayer
                                                    url={`https://youtu.be/ek4edRq0miA?si=Ui8xWHuK5T93GXZP`}
                                                    controls={true}
                                                    light={true}
                                                    width="100%"
                                                />
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>
        );
    } else {
        return null;
    }
}

function Buttons(props) {
    return(
        <div className='row'>
            <div className='col-12 col-sm-6'>
                <button
                    type="button"
                    className="std-button"
                    style={{backgroundColor: '#656565'}}
                    onClick={() => props.back()}
                >
                    <div
                        className="text-center"
                        style={{color: '#ededed'}}
                    >
                        BACK
                    </div>
                </button>
            </div>
            <div className='col-12 col-sm-6'>
                <button
                    type="button"
                    className="std-button"
                    style={
                        props.allowNext
                        ? {backgroundColor: '#fcd902'}
                        : {cursor: 'initial', backgroundColor: '#656565', opacity: '0.5'}
                    }
                    onClick={() => {
                        if(props.allowNext) {
                            props.next();
                        }
                    }}
                >
                    <div
                        className="text-center"
                        style={props.allowNext ? {color: '#282725'} : {color: '#ededed'}}
                    >
                        NEXT
                    </div>
                </button>
            </div>
        </div>
    );
}
