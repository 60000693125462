import React, { useEffect } from "react";

import { GameDetailsAchievement } from "./Achievement";

const DEBUG = false;


// This is being called 3 times, idk why, gl (doesn't matter because it's only monting once tho) (also the problem is from way beyond, so don't even bother XDDD)
export function GameDetailsAchievements({
  game,
  achievements,
  statistics, // {totalGamers:number, achievementCompletitions:{ [alias:number] } }
  playerStats, // {  [game] : [{alias, fee, reward, lastEarned, repeated?}]  } // If (undefined / null) it will be as not logged in
  sort, filter, search,// sortGet, sortSet, filterGet, filterSet, titleGet, titleSet
}) {
  const sharedExpanded = React.useState();
  const [achievementSorted, setAchievementSorted] = React.useState(achievements);
  

  function handleSort(achievements, sort) {
    if(!sort)
      return achievements;

    switch(sort) {
      case 'default':
        return achievements;

      case 'completed':
        const psAliases = new Set(playerStats[game].map(a => a.alias)); //alias, lastEarned
        const notInPsAliases = achievements.filter(a => !psAliases.has(a.alias));
        const inPsAliases = achievements.filter(a => psAliases.has(a.alias)).sort((a, b) => a.lastEarned > b.lastEarned ? 1 : -1);
        return [...inPsAliases, ...notInPsAliases];
        
      case 'name':
        return achievements.sort((a, b) => { // This seems crazy, but it separates the numbers from strings and sorts the numbers correctly (ASTROBLAST RAMPAGE REEEEEEEEEEEE) It just works
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
    
            const regex = /(\d+)/g;
            const partsA = nameA.split(regex).map(part => isNaN(part) ? part : parseInt(part, 10));
            const partsB = nameB.split(regex).map(part => isNaN(part) ? part : parseInt(part, 10));
    
            for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
                if (partsA[i] === undefined) return -1;
                if (partsB[i] === undefined) return 1;
                if (partsA[i] < partsB[i]) return -1;
                if (partsA[i] > partsB[i]) return 1;
            }
    
            return 0;
        });

      case 'reward':
        return achievements.sort((a,b) => Number(b.reward) - Number(a.reward));

      case 'rarest':
        return achievements.sort((a,b) => Number(statistics.achievementCompletitions[a.alias]) - Number(statistics.achievementCompletitions[b.alias]));

      default:
        console.log("Invalid sort method '"+sort+"'");
        return achievements;
    }
  }

  function handleFilter(achievements, filter) {
    if(!filter)
      return achievements;

    switch(filter) {
      case 'all':
        return achievements;

      case 'completed':
        if(!playerStats || Object.keys(playerStats).length===0) 
          return [];
        const psAliasesC = new Set(playerStats[game].map(a => a.alias));
        return achievements.filter(a => psAliasesC.has(a.alias));

      case 'uncompleted':
        if(!playerStats || Object.keys(playerStats).length===0) 
          return achievements;
        const psAliasesU = new Set(playerStats[game].map(a => a.alias));
        return achievements.filter(a => !psAliasesU.has(a.alias));

      default:
        console.log("Invalid filter method '"+filter+"'");
        return achievements;
    }
  }

  function handleSearch(achievements, title) {
    if(!title)
      return achievements;

    if(title === '') 
      return achievements;

    return achievements.filter(a => a.name.toLowerCase().includes(title.toLowerCase()));
  }

  useEffect(() => {
    if(!DEBUG) return;
    console.log("!achievements", achievements);
    console.log("!statistics", statistics);
    console.log("!playerStats", playerStats);
  }, [achievements, statistics, playerStats]);

  useEffect(() => {
    let _achievements = JSON.parse(JSON.stringify(achievements));
    _achievements = handleSearch(_achievements, search);
    _achievements = handleFilter(_achievements, filter);
    _achievements = handleSort(_achievements, sort);
    if(DEBUG) console.log("achievements", _achievements);
    setAchievementSorted(_achievements)
  }, [sort, filter, search, achievements]);  

  return (
    <div key={`${game + '-achievements'}`}>
      {achievementSorted.map((i) => { // Man, I'm sorry about this, this is a mess, I'm sorry xD ( just look at {info} )
        const s0 = statistics ? statistics : null;
        const s1 = s0 ? s0.achievementCompletitions : null;
        const s2 = s1 ? s1[i.alias] : null;
        const completeRate = s2 || s2===0? s2 / s0.totalGamers * 100 : -1;
        
        const listOfAchievements =  playerStats ? playerStats[game] ? playerStats[game] : [] : [];
        const hasAchievement = listOfAchievements ? listOfAchievements.find(a=>a.alias === i.alias) : null
        const lastEarned = hasAchievement ? hasAchievement.lastEarned : null;
        const completeDate =  lastEarned ? String(lastEarned).slice(0,10).replace(/-/g,'/') : "";

        const loggedIn = playerStats ? true : false;
        
        const info = {completeRate, completeDate, loggedIn };

        const isExpanded = sharedExpanded[0] === i.alias;
        return <GameDetailsAchievement 
          game={game}
          achievement={i}
          expanded={isExpanded}
          onClick={()=>sharedExpanded[1](isExpanded ? null : i.alias)}
          info={info}
          key={`${game + '-' + i.alias}`}/>
      })}
    </div>
  );
}