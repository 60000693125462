import React from "react";
import {Redirect} from "react-router-dom";

import GameActSlider from "./gamedev/activityMainSlider";
import GamedevApplicationActSlider from "./gamedev/applicationManagement/activityMainSlider";
import { info } from "sass";

//import QUDOServer from "../helpers/QUDOServerConnection";

import { useSelector } from "react-redux";

class UserActivity extends React.Component {
	constructor(props) {
		super(props);
        this.state = {
            isEditing: false
        }
    }

    /*
	componentDidMount() {
        this.updateResources();
	}
    */

    /*
    updateResources = async () => {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/resources`, {
            withCredentials: true
        })
        .then(async result => {
            await this.setState((state) => {
                state.resources = [];

                for (let game in result.data) {
                    state.resources[game] = result.data[game]
                }

                return state;
            });
        });
    }
    */

    addgame = async () => {
        this.setState({
            addGame: true
        });
    }

    addapp = async () => {
        this.setState({
            addApp: true
        });
    }

    
	render() {
        if(this.state.addGame === true) {
            return <Redirect push to={process.env.REACT_APP_GAMEDEV_ADD_GAME}/>
        }

        if(this.state.addApp === true) {
            return <Redirect push to={process.env.REACT_APP_GAMEDEV_ADD_APP}/>
        }

		return (
            <div className="row position-relative">
                <div className="col-sm-12 text-left" id="title-box">
                    <div id="title">Your Games</div>
                </div>

                <div className="position-absolute " style={{top: "20px", right: "60px", display:"flex", gap:"16px"}}>
                    <div id="addgame" onClick={this.addgame}>ADD GAME</div>
                    {/* Trying to use a dropdown at the side of "ADD GAME" for other options
                    <Dropdown >
                        <Dropdown.Toggle id="dropdown-basic">
                            Sort
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">By Name</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">By Date</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>*/ 
                    }
                </div>

                <div className="position-fixed " style={{bottom: "30px", right: "60px", display:"flex", gap:"16px"}}>
                    <div id="addapp" onClick={this.addapp}>ADD APP</div>
                </div>

                <div className="col-12 gamedevact-main-container" /* style={{height: "100%"}} */>
                    {/*
                    this.state.resources && (
                        <GameActSlider resources={this.state.resources}/>
                    )
                    */}

                    <GameActSlider/>
                </div>
                {(
                <>
                

                <div className="col-12 gamedevact-main-container"> 
                    <GamedevApplicationActSlider />
                </div>
                </>
                )}

            </div>
		);
	}
}

export default UserActivity;
