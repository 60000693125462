import React, {useState, useEffect} from "react";

const medal = require("../../../../images/definitive/icn-medal-updated.png");
const medalWow = require("../../../../images/definitive/icn-medal-achievement.svg");
const iconUp = require("../../../../images/definitive/icn-up.png");
const iconDown = require("../../../../images/definitive/icn-down.png");

const WOW_THRESHOLD = 5;

/* info {
    completed: boolean,
    completeDate: string,
    completeRate: number, // The percentage of users that have completed this achievement
} */
export function GameDetailsAchievement({ 
    game,
    achievement,
    onClick,
    expanded = false,
    info = {}, // completeRate, completeDate, loggedIn
 }) {
    if(!info) info = {}; // To make sure it exists ateast, avoiding erros

    const completed = info ? info.completeDate ? true : false : false;
    const completeRate = info ? info.completeRate || info.completeRate===0 ? !isNaN(info.completeRate)? parseFloat(Number(info.completeRate).toFixed(1)) :-1:-1:-1;
    const completeDate = info ? info.completeDate ? info.completeDate : "" : "";

    const [integerRate, decimalRate] = completeRate > 0 ? String(completeRate).split('.') : [0,0];

    //const wow = completeRate >= 0 && completeRate <= WOW_THRESHOLD ? completed : false;
    const wow = completeRate >= 0 && completeRate <= WOW_THRESHOLD ? true : false;

    return (
    <div key={`${game + '_' + achievement.alias}`} className={`row achievement-card${wow?'-wow':''} mx-auto col-12 p-0`} onClick={onClick}>
        
        <div className={`achievement-first-part${wow?'-wow':''}`}>
            <div className="achievement-first-part-upper">
                <div className="col-12 p-0 flex">
                    <img className="col-6 p-0 icon-32px-force" style={{objectFit:"scale-down"}} src={expanded ? iconUp : iconDown} alt={`${expanded?'close':'open'}`}/>
                    <img className="col-6 p-0 icon-32px-force" style={{objectFit:"scale-down"}} src={wow ? medalWow : medal} alt={"medal icon"}/>
                </div>
            </div>
        </div>

        <div className={`achievement-second-part${wow?'-wow':''}`}>
            <div className="achievement-second-part-upper">
                <div className="flex">
                    <div className="achievement-title">
                        {achievement.name}
                    </div>
                    <div className="achievement-info-part">
                        <div className="achievement-reward-part">
                            <div className="achievement-reward-value">
                                {achievement.reward}
                            </div>
                        </div>
                        <div className="achievement-completed-part">
                            <div className="achievement-completed-percentage">
                                {completeRate < 0 
                                ? `...` 
                                : (<>
                                    {integerRate}
                                    {decimalRate > 0 && <>.<span style={{fontSize:'18px'}}>{decimalRate}</span></>}
                                    <span style={{fontSize:'18px'}}>%</span>
                                </>) }
                            </div> 
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <AchievementSecondPartInfo />
                </div>
            </div>
            {expanded && 
            <div className="achievement-second-part-bottom">
                {achievement.description}
            </div>
            }
        </div>

        <div className={`achievement-third-part${wow?'-wow':''}`}>
            <div className="achievement-third-part-upper">
                { info.loggedIn &&
                <div className={`${completed?"achievement-completed":"achievement-uncompleted"} ${expanded?"border-radius-right-top-7":"border-radius-right-7"}`}>
                    {completed?"COMPLETED":"UNCOMPLETED"}
                </div>
                }
            </div>
            {expanded && completeDate && 
            <div className="achievement-third-part-bottom">
                {completeDate ? completeDate : ""}
            </div>
            }
        </div>

    </div>
    )
}


// Since it's fixed and don't need to be dynamic, I'm just gonna separate it
function AchievementSecondPartInfo(){
    return (
        <>
    <div className="achievement-title">

    </div>
    <div className="achievement-info-part">
        <div className="achievement-reward-part">
            <div className="achievement-reward-unit">
                Qudo
            </div>
        </div>
        <div className="achievement-completed-part">
            <div className="achievement-completed-undertext">
                gamers completed
            </div>
        </div>
    </div>
        </>
    )
}

/*
    <div className="col-2 text-center align-self-center">
        <img src={medal} alt="medal" className="icon-32px"/> 
    </div>
    <div className="col-8 align-self-center">
        <div className="achievementtitle">
        {achievment.name}
        </div>
        <div className="achievementsubtitle">
        {achievment.description}
        </div>
    </div>
    <div className="col-2 align-self-center">
        <div className="amountgreen text-right">
        {points.value}
        </div> 
        <div className="amountgrey text-right">
        {points.unit}
        </div>
    </div>
*/