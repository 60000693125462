import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import {Modal} from 'react-bootstrap';
import {ClipLoader} from 'react-spinners';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSms} from '@fortawesome/free-solid-svg-icons';

import {ButtonInput} from '../forms/buttons';
import QUDOServer from '../../../helpers/QUDOServerConnection';
import { faQuora } from '@fortawesome/free-brands-svg-icons';

export default function Verify(props) {
    const currentUser = useSelector(state => state.info);

    const [token, setToken] = useState('');
    const [adSource, setAdSource] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNr, setPhoneNr] = useState('');
    const [smsCode, setSmsCode] = useState('');
    const [telegramUserID, setTelegramUserID] = useState('');
    const [telegramUser, setTelegramUser] = useState({});
    const [recaptchaToken, setRecaptchaToken] = useState('');

    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showCodeInput, setShowCodeInput] = useState(false);
    const [showSMSWarning, setShowSMSWarning] = useState(false);
    const [accountVerified, setAccountVerified] = useState(false);
    const [verificationMethod, setVerificationMethod] = useState('');

    useEffect(() => {
		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);

		const token = params.get('token');
        const adSource = params.get('adsource');
        setAdSource(adSource);

        if(global.isDev){
            setToken(token);
            setUsername("DevUser_JnK");
            if(global.isDev) console.log("Verify->useEffect\nToken:", token, "\nAdSource:", adSource);
            setLoading(false);
            //return;
        };

        validateToken(token);
        
    }, []);

    function validateToken(token) {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/auth/validatetoken`, {
            params: {
                token: token
            }
        })
        .then((response) => {
            setToken(response.data.token);
            setUsername(response.data.display_name ? response.data.display_name : response.data.username);
            setEmail(response.data.email)
            QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/auth/verificationscreenopened`, {
                params: {
                    token: token
                }
            })
        })
        .catch((error) => {
            setErrorMessage(error.errorMessage);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    function sendSMS() {
        if(!phoneNr) {
            return;
        }

        setLoading(true);
        setErrorMessage('');

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/auth/sendverificationsms`, {
            token: token,
            phone: `+${phoneNr}`
        })
        .then((response) => {
            setShowCodeInput(true);
        })
        .catch((error) => {
            setErrorMessage(error.errorMessage);
        })
        .finally(() => {
            setLoading(false);
            setShowSMSWarning(true);
        });
    }

    function verify() {
        let skip = verificationMethod === "QA";
        if(!adSource && !smsCode && !telegramUserID && !skip) {
            console.log("returning from verify");
            return;
        }
        if(global.isDev) console.log("Verify called");

        setLoading(true);
        setErrorMessage('');

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/auth/verifyaccount`, {
            token: token,
            code: smsCode,
            telegramUserID: telegramUserID,
            telegramUser: JSON.stringify(telegramUser),
            recaptchaToken: recaptchaToken,
            adSource: adSource,
            verificationMethod: verificationMethod
        })
        .then((response) => {
            if(global.isDev){ console.log("verify->then called");
                console.log(response);
            }
            setAccountVerified(true);
        })
        .catch((error) => {
            if(global.isDev){ console.log("verify->error called");
            }
            setErrorMessage(error.errorMessage);
        })
        .finally(() => {
            if(global.isDev){ console.log("verify->finally called");
                }
            setLoading(false);
            if(verificationMethod === "sms") {
                setRecaptchaToken('');
            }
        });
    }

    function close() {
        if(accountVerified) {
            setRedirect(true);

            setTimeout(() => {
                props.setShow(null);
            }, 500);
        } else {
            props.setShow(null);
        }
    }

    useEffect(() => {
        verify();
    }, [telegramUserID]);

    useEffect(() => {
        if (verificationMethod === 'telegrammail' || verificationMethod === 'QA') {
            verify();
        }
    }, [verificationMethod]);

    useEffect(() => {
        if (adSource && token) {
            setVerificationMethod('telegrammail');
        }
    }, [adSource, token]);

    function TelegramLogin() {
        useEffect(() => {
          // Define the function
          window.onTelegramAuth = (user) => {
            if(global.isDev){
                console.log('Logged in as ' + user.first_name + ' ' + user.last_name + ' (' + user.id + (user.username ? ', @' + user.username : '') + ')');
            }
            setVerificationMethod("telegram")
            setTelegramUser(user); // {authdate: timestamp, first_name: string, hash: string, id: number, last_name: string, username: string, photo_url: string}
            setTelegramUserID(user.id); // Set last, as it triggers the verification
            console.log("Open Telegram bot link: https://t.me/qudocoin")
            //window.open('https://t.me/qudocoin', '_blank')
        };
            //setTimeout(() => {
                const botName = process.env.REACT_APP_TELEGRAM_BOT
                if(global.isDev) console.log("TelegramLogin useEffect on ", botName);
                // Create script element
                const script = document.createElement('script');
                script.src = 'https://telegram.org/js/telegram-widget.js?22';
                script.async = true;
                script.dataset.telegramLogin = botName;
                script.dataset.userpic = 'false';
                script.dataset.size = 'large';
                script.dataset.radius = '5';
                script.dataset.onauth = 'onTelegramAuth(user)';
                script.dataset.requestAccess = 'write';

            
                document.getElementById('telegram-login').appendChild(script);
                // Append the script to the body
                //document.body.appendChild(script);
                if(global.isDev) console.log(script);
            
                // Clean up on component unmount
                return () => {
                    if(script){
                        console.log("TelegramLogin useEffect cleanup")
                        document.getElementById('telegram-login').removeChild(script);
                        //document.body.removeChild(script);
                    }
                };
            //}, 10);
        }, []);
      
        return <div id="telegram-login" style={{marginBottom: "8px"}}></div>;
    }

    function SMSVerification() {
        //const [showSMSVerification, setShowSMSVerification] = useState(false);

        //const smsStyles = "smsstyles " + (showSMSVerification ? 'smsdisplay' : 'smshidden');
        const smsStyles = "smsstyles smsdisplay";

        return (
            <>
                {/* <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="my-2 enable-click user-select-none" style={{ color: "#9e9e9e" }}
                        onClick={() => setShowSMSVerification((prev) => !prev)}>
                        Having trouble with Telegram?
                        {showSMSVerification ? (
                            <FontAwesomeIcon icon={faChevronUp} className='ml-1' />
                        ) : (
                            <FontAwesomeIcon icon={faChevronDown} className='ml-1' />
                        )}
                    </div>
                </div> */}
                <div style={{ display: "flex", justifyContent: "center" }}
                    className={smsStyles}>
                    <div
                        style={{
                            width: '215px', height: '40px', color: 'black', backgroundColor: 'rgb(252 217 2 / 70%)', borderRadius: '5px', cursor: 'pointer',
                            display: "flex", flexWrap: "wrap", justifyContent: "center", alignContent: "center", alignItems: "center",
                            fontSize: "16px", lineHeight: "20px", padding: "9px 21px 11px"
                        }}
                        onClick={() => setVerificationMethod("sms")}
                    >
                        <FontAwesomeIcon icon={faSms} size='lg' style={{ margin: "0 13px -2px -7px" }} />
                        {' '}
                        Verify with SMS
                    </div>
                </div>
            </>
        );
    }

    function QAVerification() {

        if(!global.isDev){
            if(!email || email !== global.testAccEmail){
                return(<></>);
            }
        } 

        
        return (
            <>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "16px"}}>
                <div style={{
                        width: '215px', height: '40px', color: 'black', backgroundColor: `${String(process.env.REACT_APP_QUDO_SERVER).includes('qa1') ? 'rgb(252 147 2 / 70%)' : 'rgb(252 20 2 / 70%)'}`, borderRadius: '5px', cursor: 'pointer',
                        display: "flex", flexWrap: "wrap", justifyContent: "center", alignContent: "center", alignItems: "center",
                        fontSize: "16px", lineHeight: "20px", padding: "9px 21px 11px"
                    }}
                    onClick={() => setVerificationMethod("QA")}
                >
                    <FontAwesomeIcon icon={faQuora} size='lg' style={{ margin: "0 13px -2px -7px" }} />
                    {' '}
                    Verify by QA 
                </div>
            </div>
            </>
        );
    }

    return(
		<Modal
            show={props.show}
            onHide={!loading ? close : () => {}}
            backdrop='static'
            //dialogClassName='meta-box'
		>
            <>
            {!loading && (
                <Modal.Header
                    closeButton
                    className='modal-header-position'
                />
            )}
            <Modal.Body>
                {currentUser ? (
                    <div className="row meta-body">
                        <div className="col-12 login-main-text my-3">
                            You can't verify a new account while logged in. Log out and try again.
                        </div>
                    </div>
                ) : (
                    <div className="row meta-body">
                        <div className="col-12 login-main-text my-0">
                            Verify Account
                        </div>
                        <div className="col-12">
                            {loading ? (
                                <div className="text-center mt-5">
                                    <ClipLoader/>
                                </div>
                            ) : (
                                <>
                                {(token && username) ? (
                                    <>
                                    {accountVerified ? (
                                        <div className="text-center my-5">
                                            <h5 className="my-1">Success</h5>
                                            <div>
                                                Your account is now verified. Please proceed to log in to access your account.
                                            </div>
                                            {redirect && (
                                                <Redirect push to={process.env.REACT_APP_HOME}/>
                                            )}
                                        </div>
                                    ) : (
                                        <>
                                        {(verificationMethod === "" || verificationMethod === "telegram" || verificationMethod === "telegrammail") && (
                                            <div className="mt-3">
                                                <div>
                                                    <div className="my-1">Hi <b>{username}</b>,</div>
                                                    <div className="my-1">{
                                                        adSource ? (<>Confirm your account via telegram login <strong>and comeback to this page</strong>:</>) :
                                                        "Telegram verification: Fast & Secure."
                                                        }
                                                    </div>
                                                    <div className='my-4 text-center'>
                                                        <div id="telegram_support_widget">
                                                            <TelegramLogin />
                                                        </div>
                                                        {!adSource && (
                                                            <SMSVerification />
                                                        )}
                                                        
                                                            <QAVerification />
                                                        
                                                    </div>
                                                    {errorMessage && errorMessage === "Please select the captcha" && (
                                                        <ReCAPTCHA
                                                            onChange={setRecaptchaToken}
                                                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}
                                                        />
                                                    )}
                                                    {errorMessage && (
                                                        <div className="mt-3" style={{color: "red"}}>
                                                            <h5 className="my-1">Error</h5>
                                                            <div>{errorMessage}</div>
                                                        </div>
                                                    )}
                                                    <div className='mt-4 mb-2 text-center' style={{marginTop:"36px"}}>
                                                        <a
                                                            href='mailto:qudo.support@blockbastards.io'
                                                            className='d-inline-block mx-1'
                                                            style={{color: 'black'}}
                                                        >
                                                            <u>Need help? Contact us!</u>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {verificationMethod === "sms" && (
                                            <div className="mt-3">
                                                <div>
                                                    <div className="my-1">Hi <b>{username}</b>,</div>
                                                    <div className="my-1">
                                                        To confirm your account, please enter your phone number below and press the "Get Code" button.
                                                        You will receive a verification code via SMS.
                                                    </div>
                                                </div>
                                                <div className="text-center mt-3">
                                                    <div style={{display: 'inline-block'}}>
                                                        <PhoneInput
                                                            value={phoneNr}
                                                            onChange={setPhoneNr}
                                                            country={'us'}
                                                            countryCodeEditable={false}
                                                        />
                                                    </div>
                                                    <div>
                                                        <ButtonInput
                                                            trigger
                                                            set={sendSMS}
                                                            //disabled={!phoneNr}
                                                            label="Get Code"
                                                            className="form-submit-btn text-center my-2"
                                                            style={{maxWidth: '175px', height: '1rem', lineHeight: '0rem'}}
                                                        />
                                                    </div>
                                                </div>
                                                {showCodeInput && (
                                                    <div>
                                                        <div className="mt-2">
                                                            Please insert the code you received on your phone. 
                                                            The SMS message can take a couple of minutes to be delivered.
                                                        </div>
                                                    </div>
                                                )}
                                                    <div>
                                                        <div className='text-center mt-2'>
                                                            <input
                                                                type='text'
                                                                className='text-center input-field-custom'
                                                                style={{maxWidth: '200px', fontSize: '1.5rem'}}
                                                                placeholder='SMS Code'
                                                                value={smsCode}
                                                                onChange={(e) => setSmsCode(e.target.value)}
                                                            />
                                                            <div className='d-inline-block mt-2'>
                                                                <ReCAPTCHA
                                                                    onChange={setRecaptchaToken}
                                                                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_PUBLIC_KEY}
                                                                />
                                                            </div>
                                                            {showSMSWarning && (
                                                                <div className='my-2'>
                                                                    The SMS service we use to verify new accounts is unfortunately restricted in some jurisdictions.
                                                                    Please contact us at
                                                                    <a
                                                                        href='mailto:qudo.support@blockbastards.io'
                                                                        className='mx-1'
                                                                    >
                                                                        qudo.support@blockbastards.io
                                                                    </a>
                                                                    if you are having problems with the verification process.
                                                                </div>
                                                            )}
                                                            <div>
                                                                <ButtonInput
                                                                    trigger
                                                                    set={verify}
                                                                    label="Submit"
                                                                    className="form-submit-btn text-center my-1"
                                                                    style={{maxWidth: '150px', height: '1rem', lineHeight: '0rem'}}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                {errorMessage && (
                                                    <div
                                                        className="text-center mt-3"
                                                        style={{color: "red"}}
                                                    >
                                                        {errorMessage}
                                                    </div>
                                                )}
                                                <div className='text-center'>
                                                    <div
                                                        className='d-inline-block mt-4 mb-2'
                                                        style={{cursor: 'pointer'}}
                                                        onClick={() => {
                                                            setPhoneNr("");
                                                            setSmsCode("");
                                                            setVerificationMethod("");
                                                        }}
                                                    >
                                                        <u>back</u>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {verificationMethod === "QA" && (
                                            <div>
                                                <div className="my-2">{"QA only method called."}</div>
                                            </div>
                                        )}
                                        </>
                                    )}
                                    </>
                                ) : (
                                    <div className="mt-3" style={{color: "red"}}>
                                        <h5 className="my-1">Error</h5>
                                        <div>{errorMessage}</div>
                                    </div>
                                )}
                                </>
                            )}
                        </div>
                    </div>
                )}
            </Modal.Body>
            </>
        </Modal>
    );
}
