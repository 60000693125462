import React, { useState, useEffect } from "react";
import {useHistory} from "react-router-dom";
import Moment from 'moment-timezone';
import ReactTooltip from "react-tooltip";
import HighchartsReact from 'highcharts-react-official';
import { useDispatch, useSelector } from "react-redux";
import "react-alice-carousel/lib/alice-carousel.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';

import JoinGuildBanner from "../components/community/joinGuildBanner";
import GamedevsList from "../components/community/gamedevsList";
import ProvidersList from "../components/community/providersList";
import TopGuilds from "../components/community/topGuilds";
import TopPlayers from "../components/community/topPlayers";
//import ThisWeekSlider from "../components/community/thisWeekSlider";
import GamesList from "../components/community/gamesList";
import TopStakeholders from "../components/community/topStakeholders";
import {TopRewardingGames, TopRewardingTotals} from "../components/community/topRewardingGames";
import LatestRewards from "./community/latestRewards";
import GameDetailsModal from "./components/modals/GameDetailsModal";
import GuildDetailsModal from "./components/modals/GuildDetailsModal";
import GamedevDetailsModal from "./components/modals/GamedevDetailsModal";
import ProviderDetailsModal from "./components/modals/ProviderDetailsModal";
import Footer from "./community/footer";

import * as actions from "../actions";

import useWindowSize from "../helpers/useWindowSize";
import QUDOServer from "../helpers/QUDOServerConnection";
import TopReferrals from "./community/topReferrals";

var Highcharts = require("highcharts");

// Load module after Highcharts is loaded
require("highcharts/modules/exporting")(Highcharts);

const arrow = require("../images/definitive/icn-arrow-forward.png");
const bigLogo = require("../images/definitive/QUDO_ICON_Black.svg");

class PriceChart extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			chartOptions: {
				chart: {
					type: "area",
					backgroundColor: "transparent",
					height: "200rem",
				},
				title: {
					text: "",
				},
				subtitle: {
					align: "right",
					verticalAlign: "bottom",
				},
				legend: {
					enabled: false,
				},
				colors: [
					"#fafafa",
					"#000000",
					"#000000",
					"#000000",
					"#000000",
					"#000000",
					"#000000",
					"#000000",
					"#000000",
					"#000000",
				],
				xAxis: {
					categories: null,
					lineColor: "transparent",
					labels: {
						style: {
							color: "#fafafa",
						},
					},
					reversed: true,
				},
				yAxis: {
					title: {
						text: "",
					},
					gridLineColor: "transparent",
					// min: 2.6,
					// max: 3.3,
					opposite: true,
					labels: {
						style: {
							color: "#fafafa",
						},
					},
				},
				plotOptions: {
					area: {
						fillOpacity: 0.5,
						fillColor: {
							linearGradient: [0, 0, 0, 300],
							stops: [
								[0, "#505050"],
								[1, "#232323"],
							],
						},
					},
				},
				credits: {
					enabled: false,
				},
				series: [
					{
						name: "Price",
						data: null,
						marker: {
							fillColor: "#fbd731",
						},
					},
				],
			}
		}
	}

	componentWillMount() {
		const timezone = Moment.tz.guess();

		this.setState({timezone: timezone});
	}

	componentDidMount() {
		QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/history/prices/TLOS?count=7`)
		.then((result) => {
			this.setState({
				chartOptions: {
					xAxis: {
						categories: result.data.map((element) => {
							return Moment(new Date(element.timestamp)).tz(this.state.timezone).format("DD MMM");
						})
					},
					series: [{
						data: result.data.map((element) => {
							return Number(element.price.toFixed(4));
						})
					}]
				}
			});
		});
	}

	render() {
		const { chartOptions } = this.state;

		return (
			<HighchartsReact
				highcharts={Highcharts}
				options={chartOptions}
			/>
		);
	}
}

function CommunityContent({ gamesDetailsModal, gamedevsDetailsModal, providerDetailsModal }) {
	const dispatch = useDispatch();

	const games = useSelector(state => state.games);

	useEffect(() => {
		if(!games) {
			dispatch(actions.getGames());
		}
	}, []);

	if(games) {
		return(
			<div className="community-background">
				<GamesList
					games={games}
					showDetails={gamesDetailsModal}
				/>
				<JoinGuildBanner/>
				<TopRewardingGames/>
				<TopGuilds/>
				<GamedevsList
					games={games}
					showDetails={gamedevsDetailsModal}
				/>
				<hr className="main-hr my-0"/>
				<TopPlayers/>
				<ProvidersList
					showDetails={providerDetailsModal}
				/>
				<TopReferrals/>
				<TopStakeholders/>
				<LatestRewards/>
				<Footer/>
			</div>
		);
	} else {
		return null;
	}
}

export function Header({hideAirdrop}) {
	const history = useHistory();
	const windowSize = useWindowSize();

	const info = useSelector(state => state.info);

	return (
		<div
			className="row mx-0"
			style={{overflow: 'hidden', backgroundColor: '#fcc404'}}
		>
			{windowSize && windowSize.width < 768 ? (
				<>
				<div className={`col-5 pr-0 my-auto text-center`}>
					<img
						alt="QUDO logo"
						src={bigLogo}
						style={{maxHeight: '200px'}}
						className="img-fluid"
					/>
				</div>

				<div className={`col-7 pl-0 my-auto`}>
					<div className="row my-4">
						<div className="col-12">
							<div id="community-welcome" className="d-inline-block">
								Welcome to the Qudo Community
							</div>
						</div>

						{windowSize &&  windowSize.width >= 435 && (
							<div className="col-12 mt-2">
								<div
									id="community-welcome-message"
									style={{maxWidth: '400px'}}
								>
									QUDO is a proof-of-gameplay reward mechanism aiming to revolutionize the gaming industry.
								</div>
								<a
									target="_blank"
									style={{ color: "black" }}
									rel="noopener noreferrer"
									href="http://www.qudo.io"
									className="know-more"
								>
									KNOW MORE
									<img
										src={arrow}
										className="ml-3 mb-1"
										alt="arrow"
									/>
								</a>
							</div>
						)}
					</div>
				</div>

				{windowSize && windowSize.width < 435 && (
					<div className="col-12 mt-0 mb-3">
						<div className="mx-auto" style={{maxWidth: '85%'}}>
							<div id="community-welcome-message">
								QUDO is a proof-of-gameplay reward mechanism aiming to revolutionize the gaming industry.
							</div>
							<a
								target="_blank"
								style={{ color: "black" }}
								rel="noopener noreferrer"
								href="http://www.qudo.io"
								className="know-more"
							>
								KNOW MORE
								<img
									src={arrow}
									className="ml-3 mb-1"
									alt="arrow"
								/>
							</a>
						</div>
					</div>
				)}
				</>
			) : (
				<>
				<div className={`col-3 my-auto text-center`}>
					<img
						alt="QUDO logo"
						src={bigLogo}
						style={{maxHeight: '200px'}}
						className="img-fluid"
					/>
				</div>

				<div className={`col-4 my-auto py-4`}>
					<div
						id="community-welcome"
						className="d-inline-block"
						style={{maxWidth: windowSize && windowSize.width < 992 ? '100%' : '320px'}}
					>
						Welcome to the Qudo Community
					</div>
				</div>

				<div className={`col-4 my-auto py-4`}>
					<div
						id="community-welcome-message"
						style={{maxWidth: windowSize && windowSize.width < 992 ? '100%' : '450px'}}
					>
						QUDO is a proof-of-gameplay reward mechanism aiming to revolutionize the gaming industry.
					</div>
					<a
						target="_blank"
						style={{ color: "black" }}
						rel="noopener noreferrer"
						href="http://www.qudo.io"
						className="know-more"
					>
						KNOW MORE
						<img
							src={arrow}
							className="ml-3 mb-1"
							alt="arrow"
						/>
					</a>
				</div>
				</>
			)}

			{!hideAirdrop && (
				<div
					className="col-12 px-5 py-3"
					style={{color: "#ffffff", backgroundColor: "#1869fe", cursor: "pointer"}}
					//onClick={() => window.open("https://medium.com/block-bastards/qudo-launch-airdrop-for-early-adopters-d6ca74614595", "_blank")}
					//onClick={() => history.push("?register=true")}
				>
					<div className="row">
						{info ?
							<div
								className="col-12 col-xl-6 my-1"
								onClick={() => window.open("https://medium.com/block-bastards/qudo-launch-airdrop-for-early-adopters-d6ca74614595", "_blank")}
							>
								Do not miss out on QUDO's Mainnet Launch Airdrop
							</div>
							:
							<div
								className="col-12 col-xl-6 my-1"
								onClick={() => history.push("?register=true")}
							>
								Register now to not miss out on QUDO's Mainnet Launch Airdrop
							</div>
						}
						<div
							className="col-12 col-xl-6 my-1"
							style={{fontWeight: "bold"}}
							onClick={() => window.open("https://medium.com/block-bastards/qudo-launch-airdrop-for-early-adopters-d6ca74614595", "_blank")}
						>
							<div
								className="d-inline-block"
								//onClick={() => window.open("https://medium.com/block-bastards/qudo-launch-airdrop-for-early-adopters-d6ca74614595", "_blank")}
							>
								Know more
								<div className="d-inline-block ml-2">
									<FontAwesomeIcon icon={faArrowRight}/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default function Community() {
	const history = useHistory();
	const dispatch = useDispatch();
	const windowSize = useWindowSize();

	const config = useSelector(state => state.config);
	const lastBlockReward = useSelector(state => state.block.lastBlockReward);

	const [TLOSPrice, setTLOSPrice] = useState(null);

	const [descriptionDetailsModalGame, setDescriptionDetailsModalGame] = useState(null);
	const [descriptionDetailsModalGuild, setDescriptionDetailsModalGuild] = useState(null);
	const [descriptionDetailsModalGamedev, setDescriptionDetailsModalGamedev] = useState(null);
	const [descriptionDetailsModalProvider, setDescriptionDetailsModalProvider] = useState(null);

	//const smallScreen = windowSize && windowSize.width < 435;
	const smallScreen = windowSize && windowSize.width < 576;

	useEffect(() => {
		dispatch(actions.getBlock());

		QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/history/prices/TLOS?count=1`)
		.then((result) => {
			const originalPrice = parseFloat(result.data[0].price);
			const price = originalPrice < 0.1 ? originalPrice.toFixed(3) : originalPrice.toFixed(2);

			setTLOSPrice(price);
		});

		const url = new URL(window.location.href);
		const params = new URLSearchParams(url.search);

		//console.log(url, params);

		const gameName = params.get('game');
		const guildName = params.get('guild');

		if(gameName) {
			setDescriptionDetailsModalGame(gameName);
		} else if(guildName) {
			setDescriptionDetailsModalGuild(guildName);
		}
	}, []);

	useEffect(() => {
		if(descriptionDetailsModalGame) {
			const url = new URL(window.location.href);
			const params = new URLSearchParams(url.search);

			const gameName = params.get('game');

			if(!gameName) {
				history.push('?game=' + descriptionDetailsModalGame);
			}
		} else {
			document.title = 'QUDO Games';
		}
	}, [descriptionDetailsModalGame]);

	useEffect(() => {
		if(descriptionDetailsModalGuild) {
			const url = new URL(window.location.href);
			const params = new URLSearchParams(url.search);

			const guildName = params.get('guild');

			if(!guildName) {
				history.push('?guild=' + descriptionDetailsModalGuild);
			}
		}
	}, [descriptionDetailsModalGuild]);

	return (
		<div className="row">
			<div className="left-container order-1" style={{overflow: "auto"}}>
				<Header/>
			</div>

			<div className={`left-container justify-content-center position-relative ${windowSize && windowSize.width < 992 ? "order-3" : "order-2"}`}>
				<CommunityContent 
					gamesDetailsModal={setDescriptionDetailsModalGame}
					gamedevsDetailsModal={setDescriptionDetailsModalGamedev}
					providerDetailsModal={setDescriptionDetailsModalProvider}
				/>
			</div>

			<div
				id="qudo-now"
				className={`right-container ${windowSize && windowSize.width < 992 ? "order-2" : "order-3"}`}
				style={{overflow: "auto"}}
			>
				{windowSize && windowSize.width < 992 ? (
				<>
					<div id="qudo-now-title">
						QUDO NOW
					</div>

					<div className={`row ${smallScreen ? 'align-items-end' : 'mx-2'}`}>
						<div className={`col-6 my-2`}>
							<div className="d-inline-block">
							<div className="qudo-now-text-sm" style={{fontSize: '1rem'}}>
								TLOS Price
							</div>
							<div className="qudo-now-text-huge mt-2">
								{TLOSPrice}{" "}
								<div className="d-inline-block qudo-now-text-sm">
									USD
								</div>
							</div>
							</div>
						</div>

						<div className={`col-6 my-2`}>
							<div className="d-inline-block">
							<div className="qudo-now-text-sm" style={{fontSize: '1rem'}}>
								Current Block Reward
							</div>
							{lastBlockReward && (
								<div
									data-tip
									data-for='BlockReward'
									className="qudo-now-text-huge mt-2 enable-click"
								>
									{Number(String(lastBlockReward).split(' ')[0]).toFixed(0)}{" "}
									<div className="d-inline-block qudo-now-text-sm">
										QUDO
									</div>
									<ReactTooltip id='BlockReward' type='light'>
										<div>This is the amount of QUDO tokens that are minted in each</div>
										<div>block (every minute) and then shared between all stakeholders.</div>
									</ReactTooltip>
								</div>
							)}
							</div>
						</div>

						{config && (
							<>
								<div className="col-12 qudo-now-text-sm mt-3" style={{fontSize: '1rem'}}>
									Minimum Stake
								</div>
								{config.stake_per_game && (
									<div className="col-4 qudo-now-text-huge my-2">
										<div className="d-inline-block">
											{Number(String(config.stake_per_game).split(' ')[0])}
										</div>
										<div className="d-inline-block qudo-now-text-sm ml-1">
											qudo
										</div>
										<div className="qudo-now-text-sm">
											for Game Developers
										</div>
									</div>
								)}
								{config.stake_per_service && (
									<div className="col-4 qudo-now-text-huge my-2">
										<div className="d-inline-block">
											{Number(String(config.stake_per_service).split(' ')[0])}
										</div>
										<div className="d-inline-block qudo-now-text-sm ml-1">
											qudo
										</div>
										<div className="qudo-now-text-sm">
											for Service Providers
										</div>
									</div>
								)}
								{config.stake_per_guild && (
									<div className="col-4 qudo-now-text-huge my-2">
										<div className="d-inline-block">
											{Number(String(config.stake_per_guild).split(' ')[0])}
										</div>
										<div className="d-inline-block qudo-now-text-sm ml-1">
											qudo
										</div>
										<div className="qudo-now-text-sm">
											for Gaming Guilds
										</div>
									</div>
								)}
							</>
						)}
					</div>
				</>
				) : (
				<>
					<div id="qudo-now-title" className="mb-0">
						QUDO NOW
					</div>

					<div className="qudo-now-text-huge mt-4 mb-2">
						{TLOSPrice}{" "}
						<div className="d-inline-block qudo-now-text-sm">
							USD
						</div>
					</div>

					<div className="qudo-now-text-sm">
						TLOS Price
					</div>

					<PriceChart/>

					<hr id="qudo-now-hr"/>

					<div className="qudo-now-text-sm" style={{fontSize: '1rem'}}>
						Current Block Reward
					</div>

					{lastBlockReward && (
						<div
							data-tip
							data-for='BlockReward'
							className="qudo-now-text-huge mt-3 enable-click"
						>
							{Number(String(lastBlockReward).split(' ')[0]).toFixed(0)}{" "}
							<div className="d-inline-block qudo-now-text-sm">
								qudo
							</div>
							<ReactTooltip id='BlockReward' type='light'>
								<div>This is the amount of QUDO tokens that are minted in each</div>
								<div>block (every minute) and then shared between all stakeholders.</div>
							</ReactTooltip>
						</div>
					)}

					<hr id="qudo-now-hr" style={{ marginTop: "1.5rem" }}/>

					<div className="qudo-now-text-sm" style={{fontSize: '1rem'}}>
						Minimum Stake
					</div>

					{config && (
						<div className="qudo-now-text-huge">
							<>
							{config.stake_per_game && (
								<div className="my-3">
									<div className="d-inline-block">
										{Number(String(config.stake_per_game).split(' ')[0])}
									</div>
									<div className="d-inline-block qudo-now-text-sm ml-1">
										qudo
									</div>
									<div className="qudo-now-text-sm">
										for Game Developers
									</div>
								</div>
							)}
							{config.stake_per_service && (
								<div className="my-3">
									<div className="d-inline-block">
										{Number(String(config.stake_per_service).split(' ')[0])}
									</div>
									<div className="d-inline-block qudo-now-text-sm ml-1">
										qudo
									</div>
									<div className="qudo-now-text-sm">
										for Service Providers
									</div>
								</div>
							)}
							{config.stake_per_guild && (
								<div className="my-3">
									<div className="d-inline-block">
										{Number(String(config.stake_per_guild).split(' ')[0])}
									</div>
									<div className="d-inline-block qudo-now-text-sm ml-1">
										qudo
									</div>
									<div className="qudo-now-text-sm">
										for Gaming Guilds
									</div>
								</div>
							)}
							</>
						</div>
					)}

					<hr id="qudo-now-hr" style={{ marginTop: "1.5rem" }}/>

					<TopRewardingTotals/>
				</>
				)}
			</div>

			<GameDetailsModal
				show={!!descriptionDetailsModalGame}
				setShow={setDescriptionDetailsModalGame}
				game={descriptionDetailsModalGame}
			/>
			<GamedevDetailsModal
				show={!!descriptionDetailsModalGamedev}
				setShow={setDescriptionDetailsModalGamedev}
				gamedev={descriptionDetailsModalGamedev}
			/>
			<ProviderDetailsModal
				show={!!descriptionDetailsModalProvider}
				setShow={setDescriptionDetailsModalProvider}
				provider={descriptionDetailsModalProvider}
			/>
			{descriptionDetailsModalGuild && (
				<GuildDetailsModal
					show={!!descriptionDetailsModalGuild}
					hide={setDescriptionDetailsModalGuild}
					guild={descriptionDetailsModalGuild}
				/>
			)}
		</div>
	);
}
