import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import QUDOServer from "../../../helpers/QUDOServerConnection";

import * as actions from "../../../actions";

import ApplicationDetailsEdition from "./applicationEditDetails";
import GameImageUpload from "./images/gameImageUpload";
import TitleContainer from "./titleContainer/titleContainer";
//import BugsCenterColumn from './bugs/bugsCenterColumn/bugsCenterColumn';
//import BugsLeftColumn from './bugs/bugsLeftColumn/bugsLeftColumn';

import {GoBackArrow} from "../../navigation/arrows";
import BugsNavBar from './bugs/bugsNavBar';
import RemoveApplication from "./remove/removeApplication";

function PageSelector({activepage, setActivePage, showEarnings}) {
    const subMenu = (
        <div className="col-lg-4 col-md-4 col-sm col text-center mb-0 pl-2 ml-4">
            <span
                className={`edit-screen-menu${activepage === "achievements" ? "" : "-disabled"} sub-menu`}
                style={activepage === "achievements" ? { cursor: "default" } : { cursor: "pointer" }}
                onClick={() => setActivePage("achievements")}>
                Achievements
            </span>
            <span
                className={`edit-screen-menu${activepage === "highscores" ? "" : "-disabled"} ml-3 sub-menu`}
                style={activepage === "highscores" ? { cursor: "default" } : { cursor: "pointer" }}
                onClick={() => setActivePage("highscores")}>
                Highscores
            </span>
        </div>
    );

    const subMenuCheck = () =>
    {
        return activepage === 'highscores' || activepage === 'achievements';
    }

    return (
        <>
            <div className="row ml-4 pl-2">
                {showEarnings && (
                    <span
                        className={`edit-screen-menu${activepage === "earnings" ? "" : "-disabled"} mr-3` }
                        style={activepage === "earnings" ? { cursor: "default" } : { cursor: "pointer" }}
                        onClick={() => setActivePage("earnings")}>
                        Earnings
                    </span>
                )}
                <span
                    className={`edit-screen-menu${activepage === "about" ? "" : "-disabled"} mr-3`}
                    style={activepage === "about" ? { cursor: "default" } : { cursor: "pointer" }}
                    onClick={() => setActivePage("about")}>
                    Setup
                </span>
                <span
                    className={`edit-screen-menu${subMenuCheck() ? "" : "-disabled"} mr-3`}

                    style={subMenuCheck() ? { cursor: "default" } : { cursor: "pointer" }}
                    onClick={() => setActivePage("achievements")}>
                    Rewards
                </span>
                <span
                    className={`edit-screen-menu${activepage === "products" ? "" : "-disabled"} mr-3`}
                    style={activepage === "products" ? { cursor: "default" } : { cursor: "pointer" }}
                    onClick={() => setActivePage("products")}>
                    Purchases
                </span>

                <span
                    className={`edit-screen-menu${activepage === "bugs" ? "" : "-disabled"}`}
                    style={activepage === "bugs" ? { cursor: "default" } : { cursor: "pointer" }}
                    onClick={() => setActivePage("bugs")}>
                    Bugs
                </span>
            </div>
            <div className="row col-12 col col-md col-lg-4">
            {
                (subMenuCheck()) &&
                subMenu
            }
            </div>
        </>
    )
}

function LeftColumn(props) {
    const dispatch = useDispatch();

    if(props.activepage === "about" || !props.application) {
        return (
            <>
            <ApplicationDetailsEdition
                application={props.application}
                setDummyName={props.setDummyName}
                toggleEdit={props.setEditing}
                fetchNewInfo={() => dispatch(actions.fetchUser())}
            />

            </>
        
        );
    } 
}

function RightColumn(props) {

    return (<>
        
    </>);
    
}

export default function ApplicationEditScreen(props) {
    const applications = useSelector(state => state.info.applications);
    const application = applications.find((element) => element.name === props.application);

    const [activepage, setActivePage] = useState('about');
    const [editing, setEditing] = useState(false);
    const [refreshKeys, setRefreshKeys] = useState(Math.random());

    const [submenuBugs, setSubmenuBugs] = useState('setup');
    const [activeFilterBugs, setActiveFilterBugs] = useState('all');
    const [reportChosen, setReportChosen] = useState();
    const [update, setUpdate] = useState(false);

    const [product, setProduct] = useState();
    const [highscore, setHighscore] = useState();
    const [achievement, setAchievement] = useState();

    const [dummyName, setDummyName] = useState("");
    const [stake, setStake] = useState(0);
    
    const [productAdded, setProductAdded] = useState(false);
    const [achievementAdded, setAchievementAdded] = useState(false);

    function refresh() {
        setRefreshKeys(Math.random())
        setProduct(null)
        setHighscore(null)
        setAchievement(null)
    }

    useEffect(() => {
        setEditing(false);

        if (activepage !== 'bugs')
        {
            setSubmenuBugs('setup');
        }

        return(() => {
            refresh();
        });
    }, [activepage]);

    function saveImage(e, callback) {
        const formData = new FormData();
        formData.append('image', e);
        formData.append('gamename', application ? application.name : dummyName);

        const config = {
            withCredentials: true
        }

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/application/image/replace`, formData, config)
        .then(data => {
            callback(false);
        });
    }

    const titleContainer = (
        <div className="row col-12 mb-3">
            <GoBackArrow customClass='col-custom align-self-center mt-2'/>
            <TitleContainer name={application.name} displayName={application.display_name ? application.display_name : application.name} imageID={application.imageID}/>
       </div>
    );

    const firstColumn = activepage === 'about' ?
    (
        <>
            <GameImageUpload
                isEditing={editing || true}
                name={application.name}
                displayName={application.display_name}
                imageID={application.imageID}
                saveImage={saveImage}
            />

            <RemoveApplication applicationAccount={props.application} /> 
        </>
    ) :
    (
        <>
            <LeftColumn
                activepage={activepage}
                refresh={refresh}
                achievement={achievement}
                achievementAdded={achievementAdded}
                product={product}
                productAdded={productAdded}
                highscore={highscore}
                setAchievement={setAchievement}
                setHighscore={setHighscore}
                setProduct={setProduct}
                refreshKeys={refreshKeys}
                application={application}
                setEditing={setEditing} 
                setDummyName={setDummyName}
                stake={stake}
                submenuBugs={submenuBugs}
                activeFilterBugs={activeFilterBugs}
                reportChosen={reportChosen}
                setReportChosen={setReportChosen}
                update={update}
                setUpdate={setUpdate}
            />
        </>
    );

    const secondColumn = activepage === 'about' ?
    (
        <LeftColumn
            activepage={activepage}
            refresh={refresh}
            achievement={achievement}
            product={product}
            highscore={highscore}
            setAchievement={setAchievement}
            setHighscore={setHighscore}
            setProduct={setProduct}
            refreshKeys={refreshKeys}
            application={application}
            setEditing={setEditing} 
            setDummyName={setDummyName}
            stake={stake}
        />
    ) : 
    (
        <RightColumn 
            activepage={activepage}
            application={application}
            achievement={achievement}
            achievementAdded={setAchievementAdded}
            product={product}
            productAdded={setProductAdded}
            stake={stake}
            setStake={setStake}
            highscore={highscore}
            refresh={refresh}
            submenuBugs={submenuBugs}
            reportChosen={reportChosen}
            setReportChosen={setReportChosen}
            update={update}
            setUpdate={setUpdate}
        />
    )

    return (
        <div>
            {
                (application) &&
                titleContainer
            }
            {
                (props.application) &&
                <div className="mb-2">
                    <PageSelector
                        activepage={activepage}
                        setActivePage={setActivePage}
                        showEarnings={application && application.accepted && application.account_name}
                    />
                </div>
            }
            {activepage === 'bugs' &&
                <div className="col-12 mb-3">
                    <BugsNavBar 
                        submenuBugs={submenuBugs} 
                        setSubmenuBugs={setSubmenuBugs}
                        application={application}
                        activeFilterBugs={activeFilterBugs}
                        setActiveFilterBugs={setActiveFilterBugs}
                        setReportChosen={setReportChosen}
                    />
                </div>
            }
            <div className="gamedevact-edit-screen-container row">
                <div className="col-12 col-md-12 col-lg-4 mx-auto row">
                    <div className="col-12">
                       {firstColumn}
                    </div>
                </div>

                {!props.application && (
                    <div className="col-12 col-md-4 row edit-screen-right-column mx-auto mb-4">
                        <GoBackArrow/>
                    </div>
                )}

                <div className="col-12 col-md-12 col-lg-8 row edit-screen-right-column mb-5 mx-auto pt-0">
                    <div className="col-12 col-lg-6 mb-5">
                        {secondColumn}
                    </div>

                    <div className="col-12 col-lg-6">
                        <RightColumn 
                            activepage={activepage}
                            application={application}
                            achievement={achievement}
                            product={product}
                            stake={stake}
                            setStake={setStake}
                            highscore={highscore}
                            refresh={refresh}
                            type="generic"
                            submenuBugs={submenuBugs}
                            reportChosen={reportChosen}
                            setReportChosen={setReportChosen}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
