import { MetaKeep } from 'metakeep';
// ActionType
import { APIClient, PackedTransaction, Serializer, Transaction } from '@greymass/eosio';


const DEBUG_MODE = global.isDev || false;
const METAKEEP_APP_ID = "77d8d84b-4717-4a47-bef2-1e4e45ce29d1" || process.env.REACT_APP_METAKEEP_APP_ID; // From the developer console "7****-a*********d1""


//MARK: MetaKeep
let metakeep = null;
let wallet = null;

export function createMetakeep(_options) {
    metakeep = new MetaKeep(_options);
    if(!metakeep){
        throw new Error('Metakeep failed to initialized');
    }

    return metakeep;
}

/*
createMetakeep({
	appId: METAKEEP_APP_ID || "",
	//user: {email: "1jenaike7@gmail.com"},
	//environment: "Testnet",
	//chainId: 41,
	//rpcNodeUrls: {41: "https//testnet.telos.caleos.io"},
})*/

export function getWallet(){
    return wallet;
}

export function getEOS(){
    return wallet?.eosAddress;
}


//MARK: Class
let QUDOMetakeepInstance = null;
export class QUDOMetakeep {
    chainId;
    reasonCallback;

    eosioCore;
    constructor({chainId, rpcEndpoint}) {
        this.chainId = chainId;
        this.eosioCore = new APIClient({ url: rpcEndpoint });
        QUDOMetakeepInstance = this;
    }

    setReasonCallback(callback) {
        this.reasonCallback = callback;
    }

    handleCatchError(error) {

        console.log('handleCatchError', error);
        if (error.status === 'USER_REQUEST_DENIED') {
            return new Error('Transaction signing was denied by the user');
        } 

        if(error.name === 'unsatisfied_authorization'){
            return new Error(`${error.name.split('_').map(w=>w.charAt(0).toUpperCase() + w.slice(1)).join(' ')}: ${error.message.split(' at ')[0]}`);
        }

        if(error.name === 'eosio_assert_message_exception'){
            return new Error(`Exception: ${error.error.details[0].message.split(': ')[1]}`);
        }

        if(error.status === 'INVALID_REQUEST'){
            return new Error(`Request timedout, try again`);
        }

        console.log('handleCatchError status', error.status);
        console.log('handleCatchError name', error.name);
        console.log('handleCatchError message', error.message);
        console.log('handleCatchError String', String(error));
        console.log('handleCatchError J.Str', JSON.stringify(error));

        return new Error('Chain error. Check console for more details and contact QUDO support');
    }

   
    /**
    * @param transaction    The transaction to be signed (a object that matches the RpcAPI structure).
    * @param options        Options for the transaction signing. (optional) { broadcast: boolean [If to complete the transaction] }
    */
    signTransaction = async (originalTransaction, options = {}) => {
        if (!metakeep) {
            throw new Error('metakeep is not initialized');
        }

        try {
            // expire time in seconds
            const expireSeconds = 120;

            // Retrieve transaction headers
            const info = await this.eosioCore.v1.chain.get_info();
            const header = info.getTransactionHeader(expireSeconds);

            // collect all contract abis
            const abi_promises = originalTransaction.actions.map((a) =>
                this.eosioCore.v1.chain.get_abi(a.account),
            );
            const responses = await Promise.all(abi_promises);
            const abis = responses.map(x => x.abi);
            const abis_and_names = originalTransaction.actions.map((x, i) => ({
                contract: x.account,
                abi: abis[i],
            }));

            // create complete well formed transaction
            const transaction = Transaction.from(
                {
                    ...header,
                    actions: originalTransaction.actions,
                },
                abis_and_names,
            );

            const transaction_extensions = originalTransaction.transaction_extensions ?? [];
            const context_free_actions = originalTransaction.context_free_actions ?? [];
            const delay_sec = originalTransaction.delay_sec ?? 0;
            const max_cpu_usage_ms = originalTransaction.max_cpu_usage_ms ?? 0;
            const max_net_usage_words = originalTransaction.max_net_usage_words ?? 0;
            const expiration = originalTransaction.expiration ?? transaction.expiration.toString();
            const ref_block_num = originalTransaction.ref_block_num ?? transaction.ref_block_num.toNumber();
            const ref_block_prefix = originalTransaction.ref_block_prefix ?? transaction.ref_block_prefix.toNumber();

            // convert actions to JSON
            const actions = transaction.actions.map(a => ({
                account: a.account.toJSON(),
                name: a.name.toJSON(),
                authorization: a.authorization.map((x) => ({
                    actor: x.actor.toJSON(),
                    permission: x.permission.toJSON(),
                })),
                data: a.data.toJSON(),
            }));

            // compose the complete transaction
            const complete_transaction = {
                rawTransaction: {
                    expiration,
                    ref_block_num,
                    ref_block_prefix,
                    max_net_usage_words,
                    max_cpu_usage_ms,
                    delay_sec,
                    context_free_actions,
                    actions, //: originalTransaction.actions,
                    transaction_extensions,
                },
                extraSigningData: {
                    chainId: this.chainId,
                },
            };

            // sign the transaction with metakeep
            const reason = this.reasonCallback ? this.reasonCallback(originalTransaction) : 'sign this transaction';
            const response = await metakeep.signTransaction(complete_transaction, reason);
            const signature = response.signature;


            // Pack the transaction for transport
            const packed_trx = Serializer.encode({ object: transaction });
            const packedTransaction = PackedTransaction.from({
                signatures: [signature],
                packed_context_free_data: '',
                packed_trx: packed_trx,
            });
            
            if(DEBUG_MODE){
                console.log("header", header)
                console.log("transaction", transaction);
                console.log('Packed Transaction', packedTransaction);
                console.log('signature', signature)
                console.log('transaction', transaction)
                console.log('complete_transaction', complete_transaction)
                console.log('response', response)
            }

            if (options.broadcast === false) {
                const hexPacked_trx = Array.prototype.reduce.call(packed_trx.array, (hex, byte) => hex + byte.toString(16).padStart(2, '0'), '');

                return Promise.resolve({
                    wasBroadcast: false,
                    transactionId: '',
                    status: '',
                    transaction: packedTransaction,
                    unpackedTransaction: {
                        packed_trx: hexPacked_trx,
                        signatures: [signature],
                    }
                });
            }

            // Broadcast the signed transaction to the blockchain
            const pushResponse = await this.eosioCore.v1.chain.push_transaction(
                packedTransaction,
            );

            // we compose the final response
            const finalResponse = {
                wasBroadcast: true,
                transactionId: pushResponse.transaction_id,
                status: pushResponse.processed.receipt.status,
                transaction: packedTransaction,
            };

            return Promise.resolve(finalResponse);

        } catch (e) {
            throw this.handleCatchError(e);
        }
    }

    getChainId = async () => this.chainId;
}


export function clean() {
    QUDOMetakeepInstance = null;
}

export function get() {
    return QUDOMetakeepInstance;
}

export function create(CHAINID, BLOCKCHAINHTTPENDPOINT, customReasonCallback) {
    const MKsdk = new QUDOMetakeep({ // app.get('/eos/chainInfo
        chainId: CHAINID, // 1eaa0824707c8c16bd25145493bf062aecddfeb56c736f6ba6397f3195f33c9f
        rpcEndpoint: BLOCKCHAINHTTPENDPOINT // https://testnet2api.telosarabia.net
    });

    MKsdk.setReasonCallback(customReasonCallback ? customReasonCallback :
        (transaction) => {
            // It always starts with "QUDO request your permission to "
            // 24 chars per line (2nd line - 14 already occupied with 'permission to ')
            if(DEBUG_MODE)
                console.log("setReasonCallback transaction", transaction);
            
            const action = transaction.actions[0];

            if(transaction.actions.length > 1){
                if(transaction.actions.length === 2){
                    const a2 = transaction.actions[1];
                    // Case for migration
                    if( action.name === "updateauth" && a2.name === "updateauth" &&
                        action.data.auth.keys[0].key === a2.data.auth.keys[0].key 
                    ) {
                        return `Migrate this account to the public key: '${a2.data.auth.keys[0].key.slice(0,5)}...${a2.data.auth.keys[0].key.slice(22,27)}...${a2.data.auth.keys[0].key.slice(-5)}'`;
                    }
                }
                
                console.log('Signing the transaction: ', JSON.stringify(transaction));
                return `Sign the transaction: ${transaction.actions.map(a=>`${a.name}@${a.account}`).join(', ')}`;
            }
            
            switch(action.name){
                case "transfer":
                    return `transfer ${action.data.quantity} from ${action.data.from} to ${action.data.to}`;
                case "stake":
                case "unstake":
                    return `${action.name} ${action.data.amount}`;
                default:
                    return `Sign the transaction '${action.name}': ${JSON.stringify(action.data)}`;
            }
        }
    );
    return MKsdk;
}

export function initialize(CHAINID, BLOCKCHAINHTTPENDPOINT){
    let MKsdk = get();
    if(!MKsdk){
        if(!CHAINID)
            CHAINID = String(process.env.REACT_APP_TELOS_CHAIN_ID);
        if(!BLOCKCHAINHTTPENDPOINT)
            BLOCKCHAINHTTPENDPOINT = String(process.env.REACT_APP_TELOS_HTTP_ENDPOINT);

        MKsdk = create(CHAINID, BLOCKCHAINHTTPENDPOINT);
        set(MKsdk);
    }
    return MKsdk;
}

export function set(QMInstance) {
    QUDOMetakeepInstance = QMInstance;
}





//MARK: Example

/**
 * A simple wrapper for the class
 * @param actions Build your own `Action` outside. Check example.
 * @param options Check the signTransaction function for more details, tldr: signTransaction[]
 * @returns 
 */
export async function signTransaction(email, actions, options = {}) {
    const metakeep = createMetakeep({
        appId: METAKEEP_APP_ID,
        user:{ email: email}
    });

    // Debug info
    await metakeep.getWallet().then((response)=>{
        console.log('Wallet', response);
        wallet = response.wallet;
    }).catch((error)=>{
        console.log('Metakeep error on getWallet', error);
        console.log(String(error))
    });


    try{
        const MKsdk = initialize();
        const response = MKsdk.signTransaction({ actions: actions }, options);
        return response;
    }catch(error){ 
        console.log('Metakeep error on signTransaction', error);
        console.log(error.Message);
        throw error.message;
    };
    
    
}

