import React, {useState, useEffect} from 'react';

import {Loading} from 'components/App';
import QUDOServer from 'helpers/QUDOServerConnection';


import Verify from "components/components/modals/VerifyUserModal";

import Login from "../components/components/modals/LoginUserModal";
import { LoginMessageModal, MetaModal } from "../components/components/modals/MetaModals";


export default function Test() {
    const [loading, setLoading] = useState(true);
    const [activeModal, setActiveModal] = useState("login");

    const [message, setMessage] = useState(null);


    useEffect(() => {
        console.log("Test js  useEffect", new URL(window.location.href).pathname);
        setTimeout(() => {
            setLoading(false);
        }, 200); // This is here for the loading screen to show up for a bit, a cool effect
    }, []);

    function showMessageModal(modal, msg) {
        setMessage(msg);
        setActiveModal(modal);
    }

    return(
        <div>
            {loading ? (
                <Loading/>
            ) : (
                <>
                    <div>
                        <LoginMessageModal
                            show={(activeModal === "success" || activeModal === "failure" || activeModal === "logged out") && message}
                            setShow={setActiveModal}
                            message={message}
                        />
                        <Login
                            show={activeModal === "login"}
                            setShow={setActiveModal}
                            success={(msg) => {
                                showMessageModal("success", msg);
                            }}
                            failure={(msg) => showMessageModal("failure", msg)}
                            switchToRegister={() => setActiveModal("register")}
                            switchToForgotPassword={() => setActiveModal("forgotpassword")}
                            switchToForgotUsername={() => setActiveModal("forgotusername")}
                        />
                    </div>
                </>
            )}
        </div>
    );
}
