import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {AppContainer} from "react-hot-loader";
import "bootstrap/dist/css/bootstrap.min.css";

import {UALProvider, withUAL} from "ual-reactjs-renderer";
import {Anchor} from "ual-anchor";

import style from "./App.scss";
import App from "./components/App";
import configureStore, {history} from "./configureStore";
import AirdropRankings from "./components/AirdropRankings";
import TelegramRegister from "./views/telegram/t_register";
import MetakeepFakeSDK from "./views/metakeep/metakeepFakeSDK";
import Test from "views/test";

//import sleep from "./helpers/sleep";
import QUDOServer from "./helpers/QUDOServerConnection";
import Unavailable from "views/unavailable/unavailable";

const UALApp = withUAL(App);
const store = configureStore();

global.isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
global.isDev = process.env.REACT_APP_ENVIRONMENT === "dev";
global.testAccEmail = process.env.REACT_APP_TESTEMAIL || 'development@blockbastards.io';

const isUnavailable = false;
  // (
  //   ((new Date()).getMinutes() > 0 && ((new Date()).getMinutes() < 10)) ||
  //   ((new Date()).getMinutes() > 20 && ((new Date()).getMinutes() < 30)) ||
  //   ((new Date()).getMinutes() > 40 && ((new Date()).getMinutes() < 50))
  // );

const redirectToGames = ['Blox'];

const render = (chains, authenticators, appName) => {

  if(isUnavailable) {
    ReactDOM.render(
      <AppContainer>
          <>
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"/>
            <link rel="stylesheet" href={style}/>
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,500,700&display=swap" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700&display=swap" rel="stylesheet"/>
            <Unavailable/>
          </>
        </AppContainer>,
      document.getElementById("react-root")
    );
    } else
  if(process.env.REACT_APP_ENVIRONMENT === "dev" && new URL(window.location.href).pathname.startsWith("/test")) {
    ReactDOM.render(
      <AppContainer>
          <Provider store={store}>
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"/>
            <link rel="stylesheet" href={style}/>
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,500,700&display=swap" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700&display=swap" rel="stylesheet"/>

            <Test/>
          </Provider>
        </AppContainer>,
      document.getElementById("react-root")
    );
  }else if(new URL(window.location.href).pathname.includes(process.env.REACT_APP_AIRDROP_RANKINGS)) {
    ReactDOM.render(
      <AppContainer>
        <Provider store={store}>
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"/>
          <link rel="stylesheet" href={style}/>
          <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"/>
          <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,500,700&display=swap" rel="stylesheet"/>
          <link href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700&display=swap" rel="stylesheet"/>

          <AirdropRankings/>
        </Provider>
      </AppContainer>,
      document.getElementById("react-root")
    );
  }else if(new URL(window.location.href).pathname.includes('/game')) {
    let device = "";
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      device = "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      device = "iOS";
    }

    if (device === "") {
      window.location = new URL(window.location.href).origin;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString)
    if (urlParams.get('game') && urlParams.get('game').toLowerCase() === 'blox') {
      if (device === "Android") {
        window.location = "market://details?id=com.blockbastards.tdb";
      } else if (device === "iOS") {
        window.location = "https://testflight.apple.com/join/9CD9cwXX";
      } else {
        window.location = "https://games.qudo.io/?game=Blox";
      }
    } else {
      if(!global.isDev)
        window.location = "https://games.qudo.io/";
    }
  }else if(new URL(window.location.href).pathname.includes("/t.me/register") || new URL(window.location.href).pathname.includes("/tme/register")) {
    ReactDOM.render(
      <AppContainer>
          <Provider store={store}>
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"/>
            <link rel="stylesheet" href={style}/>
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,500,700&display=swap" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700&display=swap" rel="stylesheet"/>

            <TelegramRegister/>
          </Provider>
        </AppContainer>,
        document.getElementById("react-root")
      );
  }
  else if(new URL(window.location.href).pathname.includes("/metakeepsdk")) {
    ReactDOM.render(
      <AppContainer>
          <Provider store={store}>
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"/>
            <link rel="stylesheet" href={style}/>
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,500,700&display=swap" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700&display=swap" rel="stylesheet"/>

            <MetakeepFakeSDK/>
          </Provider>
        </AppContainer>,
        document.getElementById("react-root")
      );
  }else {
    ReactDOM.render(
      <UALProvider chains={[chains]} authenticators={authenticators} appName={appName}>
        <AppContainer>
          <Provider store={store}>
            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"/>
            <link rel="stylesheet" href={style}/>
            <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,500,700&display=swap" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,700&display=swap" rel="stylesheet"/>

            <UALApp history={history}/>
          </Provider>
        </AppContainer>
      </UALProvider>,
      document.getElementById("react-root")
    );
  }
}

const APP_NAME = "QUDO";

let TELOS_CHAIN_ID = "";
let TELOS_RPC_HOST = "";
let TELOS_HTTP_ENDPOINT = "";

const TELOS_PORT = String(process.env.REACT_APP_QUDO_SERVER).includes("https://") ? 443 : 80;
const TELOS_PROTOCOL = String(process.env.REACT_APP_QUDO_SERVER).includes("https://") ? "https" : "http";

QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/eos/chainInfo`)
.then((response) => {
  TELOS_CHAIN_ID = String(response.data.chainID);
  TELOS_HTTP_ENDPOINT = String(response.data.httpEndpoint);
})
.catch((error) => {
  TELOS_CHAIN_ID = String(process.env.REACT_APP_TELOS_CHAIN_ID);
  TELOS_HTTP_ENDPOINT = String(process.env.REACT_APP_TELOS_HTTP_ENDPOINT);
})
.finally(() => {
  if(TELOS_HTTP_ENDPOINT.indexOf("://") >= 0) {
    TELOS_RPC_HOST = TELOS_HTTP_ENDPOINT.split("://")[1];
  } else {
    //It should never enter this else statement
    TELOS_RPC_HOST = TELOS_HTTP_ENDPOINT; //Doesn't make much sense, but it should never reach this anyway
  }

  const ualChainInfo = {
    chainId: TELOS_CHAIN_ID,
    rpcEndpoints: [{
      host: TELOS_RPC_HOST,
      port: TELOS_PORT,
      protocol: TELOS_PROTOCOL
    }]
  }

  const authenticators = [
    new Anchor([ualChainInfo], {appName: "QUDO"})
  ];

  render(ualChainInfo, authenticators, APP_NAME);
});
