import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
//import { Modal, Form } from "react-bootstrap";

import * as actions from "../../actions";

//import QUDOServer from "../../helpers/QUDOServerConnection";

const fallBack = require("../../images/fallbackAvatar.png");

/*
const progressBarStyle = {
    backgroundColor: "#656565",
    borderRadius: "8px",
    marginBottom: "1rem",
    height: "0.5rem"
}
*/

// function SampleNextArrow(props) {
//     const { className, onClick } = props;
//     return(
//         <div 
//         className={className}
//         onClick={onClick}
//         >
//             <img
//                 src={sliderForward}
//                 style={{width: "1.2rem", height: "1.2rem"}}
//                 alt="slider forward arrow"
//             />
//         </div>
//     );
//   }

/**
 * Converts a long string of bytes into a readable format e.g KB, MB, GB, TB, YB
 * 
 * @param {Int} num The number of bytes.
 */
/*
function readableBytes(bytes) {
    var i = Math.floor(Math.log(bytes) / Math.log(1024)),
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    return(bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
}
*/

class GameActSlider extends React.Component {
    constructor(props) {
        super(props);

        this.settings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            // slidesToScroll: 3,
            vertical: false,
            responsive: [
                /*
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        //   vertical: false,
                        //   verticalSwiping: false,
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        //   vertical: false,
                        //   verticalSwiping: false,
                    }
                },
                */
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 1,
                        //   vertical: false,
                        //   verticalSwiping: false,
                    }
                }
            ],
            // nextArrow: <SampleNextArrow />,
            // prevArrow: <SamplePrevArrow />
        }

        this.state = { messages: "" }
    }

    /*
    confirmRemovalModal = (i) => {
        this.setState({
            confirmRemovalModal: !this.state.confirmRemovalModal,
            toRemove: i
        })
    }
    */

    edit = async (i) => {
        await this.setState({
            editing: i
        });

        window.scrollTo(0, 0);
    }

    /*
    remove = (e) => {
        e.preventDefault();

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/delete`, {
            gamename: this.state.toRemove
        }, {
            withCredentials: true
        })
        .then(() => {
            this.setState({
                confirmRemovalModal: !this.state.confirmRemovalModal,
                successModal: !this.state.successModal,
                toRemove: null
            });
        })
        .catch(() => {
            this.setState({
                confirmRemovalModal: !this.state.confirmRemovalModal,
                failureModal: !this.state.failureModal,
                toRemove: null
            });
        })
        .finally(() => {
            setTimeout(() => this.props.fetchUser(), 2000);
        });
    }
    */

    /*
    modal = () => {
        return(
            <>
                <Modal show={this.state.confirmRemovalModal} onHide={() => this.setState({ confirmRemovalModal: !this.state.confirmRemovalModal })}>
                    <Modal.Body className="confirm-modal">
                        <Form onSubmit={this.remove} style={{ padding: "2rem 4rem 0rem" }}>
                            <div className="row mb-5 confirm-modal-text">
                                <div className="col-12 text-center">Are you sure you want to remove this game?</div>
                            </div>
                            <div className="row d-flex position-relative" style={{ margin: "1rem -4rem 0rem" }}>
                                <div
                                    className={`col-6 confirm-modal-button text-center enable-click`}
                                    onClick={() => this.setState({
                                        confirmRemovalModal: !this.state.confirmRemovalModal
                                    })}
                                >
                                    <input
                                        disabled
                                        value="Nooooo!"
                                        className={`col-6 confirm-modal-button text-center enable-click`}
                                        style={{border: 'none'}}
                                    />
                                </div>
                                <input value="Yes" type="submit" className={`col-6 confirm-modal-button`} />
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.successModal} onHide={() => { this.setState({ successModal: false }); setTimeout(() => this.props.fetchUser(), 2000) }}>
                    <Modal.Body className="confirm-modal">
                        <div style={{ padding: "2rem 4rem" }}>
                            <div className="confirm-modal-text text-center">
                                Sorry to see you leaving! Feel free to add another game!
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.failureModal} onHide={() => this.setState({ failureModal: false })}>
                    <Modal.Body className="confirm-modal">
                        <div style={{ padding: "2rem 4rem" }}>
                            <div className="confirm-modal-text text-center">
                                Oops! Some error occurred. Please try again or contact QUDO support.
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
    */

    render() {
        if(this.state.editing) {
            this.props.fetchUser();
            //this.props.getGames();

            return(
                <Redirect push to={`${process.env.REACT_APP_GAMEDEV_EDIT_GAME}/${this.state.editing}`}/>
            );
        }

        return(
            <div className="row">
                {this.props.info.games.length > 0 ? this.props.info.games.map(i => {
                    return(
                        <div key={i.name} className="col-12 col-md-6 col-xl-4">
                            <div
                                key={i.imageID}
                                className="position-relative gamedevact-game-card"
                                style={i.accepted ? { cursor: "pointer" } : {}}
                            >
                                <div className="gamedevact-image-container"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.edit(i.name)}
                                >
                                    <img
                                        className="game-website-gamedevact-image"
                                        src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.name}&rand=${Date.now()}`}
                                        onError={i => i.target.src = fallBack}
                                        alt="Game"
                                    />
                                </div>
                                <div className="gamedevact-textblock-game w-100">
                                    {/*
                                    !i.accepted ?
                                        <div className="position-absolute gamedevact-approval-card">
                                            <div className=" text-center d-flex justify-content-center align-items-center flex-column"
                                                style={{
                                                    height: "100px",
                                                    backgroundColor: "#fbd731"
                                                }}>
                                                <div className="gamedevact-text-bg-game" style={{ color: "black" }}>
                                                    Pending Approval
                                                </div>
                                            </div>
                                            <div
                                                onClick={() => this.confirmRemovalModal(i.name)}
                                                className="text-center d-flex justify-content-center align-items-center flex-column enable-click"
                                                style={{
                                                    height: "40px",
                                                    backgroundColor: "#f2544c"
                                                }}
                                            >
                                                <div
                                                    className="gamedevact-text-bg-game my-auto"
                                                    style={{ color: "black", fontSize: "24px" }}
                                                >
                                                    Remove
                                                </div>
                                            </div>
                                        </div>
                                    : i.requiresGDSignature ?
                                        <>
                                        <div className="position-absolute gamedevact-approval-card">
                                            <div className=" text-center d-flex justify-content-center align-items-center flex-column"
                                                style={{
                                                    height: "100px",
                                                    backgroundColor: "#fbd731"
                                                }}>
                                                <div className="gamedevact-text-bg-game" style={{ color: "black" }}>
                                                    Requires Wallet Signature
                                                </div>
                                            </div>

                                            <div className="text-center d-flex justify-content-center align-items-center flex-column"
                                                style={{
                                                    height: "40px",
                                                    backgroundColor: "#fcd902"
                                                }}>
                                                <div className="gamedevact-text-bg-game my-auto"
                                                    onClick={() => this.requireSignature(i)}
                                                    style={{ cursor: "pointer", color: "black", fontSize: "24px" }}
                                                >
                                                    Sign
                                                </div>
                                            </div>

                                        </div>
                                        <div 
                                            className="text-center d-flex justify-content-center align-items-center flex-column"
                                            style={{
                                                height: "40px",
                                                backgroundColor: "#f2544c"
                                            }}>
                                            <div className="gamedevact-text-bg-game my-auto"
                                                onClick={() => this.confirmRemovalModal(i.name)}
                                                style={{ cursor: "pointer", color: "black", fontSize: "24px" }}
                                            >
                                                Remove
                                            </div>
                                        </div>
                                        </>
                                    :
                                        this.props.resources[i.name] &&
                                        <div className="row justify-content-between">
                                            <div className="col-3 gamedevact-progress-label">
                                                RAM Used
                                            </div>
                                            <div className="col-5 gamedevact-progress-label-values">
                                                {readableBytes(this.props.resources[i.name].RAMused)} / {readableBytes(this.props.resources[i.name].RAMmax)}
                                            </div>
                                            <div className="col-4 gamedevact-progress-label-values text-right">
                                                {this.props.resources[i.name].RAMperc.toFixed(2)}%
                                            </div>
                                            <div className="col-12">
                                                <ProgressBar
                                                    max={this.props.resources[i.name].RAMmax}
                                                    now={this.props.resources[i.name].RAMused}
                                                    style={progressBarStyle} />
                                            </div>

                                            <div className="col-3 gamedevact-progress-label">
                                                CPU Used
                                            </div>
                                            <div className="col-5 gamedevact-progress-label-values">
                                                {(this.props.resources[i.name].CPUused / 1000).toFixed(2)}ms / {(this.props.resources[i.name].CPUmax / 1000).toFixed(2)}ms
                                            </div>
                                            <div className="col-4 gamedevact-progress-label-values text-right">
                                                {this.props.resources[i.name].CPUperc.toFixed(2)}%
                                            </div>
                                            <div className="col-12">
                                                <ProgressBar
                                                    max={this.props.resources[i.name].CPUmax}
                                                    now={this.props.resources[i.name].CPUused}
                                                    style={progressBarStyle} />
                                            </div>
                                            <div className="col-3 gamedevact-progress-label">
                                                NET Used
                                            </div>
                                            <div className="col-5 gamedevact-progress-label-values">
                                                {readableBytes(this.props.resources[i.name].NETused)} / {readableBytes(this.props.resources[i.name].NETmax)}
                                            </div>
                                            <div className="col-4 gamedevact-progress-label-values text-right">
                                                {this.props.resources[i.name].NETperc.toFixed(2)}%
                                            </div>
                                            <div className="col-12">
                                                <ProgressBar
                                                    max={this.props.resources[i.name].NETmax}
                                                    now={this.props.resources[i.name].NETused}
                                                    style={progressBarStyle} />
                                            </div>
                                        </div>
                                    */}

                                    {!i.accepted && (
                                        <div className="position-absolute gamedevact-approval-card">
                                            <div className=" text-center d-flex justify-content-center align-items-center flex-column"
                                                style={{
                                                    //height: "100px",
                                                    height: "125px",
                                                    backgroundColor: "#fbd731"
                                                }}>
                                                <div className="gamedevact-text-bg-game" style={{ color: "black" }}>
                                                    Pending Approval
                                                </div>
                                            </div>
                                            {/*}
                                            <div
                                                onClick={() => this.confirmRemovalModal(i.name)}
                                                className="text-center d-flex justify-content-center align-items-center flex-column enable-click"
                                                style={{
                                                    height: "40px",
                                                    backgroundColor: "#f2544c"
                                                }}
                                            >
                                                <div
                                                    className="gamedevact-text-bg-game my-auto"
                                                    style={{ color: "black", fontSize: "24px" }}
                                                >
                                                    Remove
                                                </div>
                                            </div>
                                            {*/}
                                        </div>
                                    )}

                                    {/*
                                    <div className="gamedevact-progress">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="10" aria-valuemax="100"></div>
                                    </div>
                                    */}

                                    <div
                                        className="gamedevact-text-bg-game"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this.edit(i.name)}
                                    >
                                        {i.display_name ? i.display_name : i.name}
                                    </div>

                                    <div
                                        className="gamedevact-description-game scroll-25"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this.edit(i.name)}
                                    >
                                        <div
                                            className="gamedevact-text-sm"
                                            style={{whiteSpace: 'pre-line'}}
                                        >
                                            {i.description}
                                        </div>

                                        <div className="gamedevact-link">
                                            {i.website}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }) : (
                    <h5 className="mt-4 mx-2">
                        You don't have any games yet! :)
                    </h5>
                )}

                {/* {this.modal()} */}
            </div>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUser: bindActionCreators(actions.fetchUser, dispatch),
        getGames: bindActionCreators(actions.getGames, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        info: state.info,
        ual: state.ual
        // games: state.games
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GameActSlider);
