import React, {useState, useEffect} from 'react';

import {Loading} from '../../components/App';

import * as MK from '../../helpers/metakeep';
import QUDOServer, {AxiosMap} from 'helpers/QUDOServerConnection';
import { decodeBase64 } from 'helpers/decoder';


const DEBUG = true;


/*
    callbackLocation ! IMPORTANT
        is currently useless, but it's an example on how to handle custom callback locations
        This is usefull to make this websitesite dynamic.
        Now, how it's received and handled still needs it's own study and implementation (altough current implementation is working)
*/

function handleCallbackLocation(callbackLocation, default_method="post", separator='://') {
    if(callbackLocation.split(separator).length > 2) { // meaning it's post://htps://... and not just https://...
        return ({
            method: callbackLocation.split(separator)[0].toLowerCase(),
            url: handleCallbackLocation.split(separator, 1)[1]
        })
    }

    return ({method: default_method, url: callbackLocation});

}


/**
 * Call to API or callbackLocation to update the data of a UUID (post:// before the url to use POST method)
 * @param {object} input {old_uuid, new_uuid, data, callbackLocation}
 */
function updateUUIDData(input) {
    const {old_uuid, new_uuid, data, callbackLocation} = input;
    const DEFAULT_METHOD = 'put'; // default method to use on our the API calls

    const apiUrl = callbackLocation || `${process.env.REACT_APP_QUDO_SERVER}/pollingservice`;
    const body = {
        uuid: old_uuid,
        newuuid: new_uuid,
        newdata: JSON.stringify(data),
    };

    const {method, url} = handleCallbackLocation(apiUrl, DEFAULT_METHOD);

    if(DEBUG) console.log("updateUUIDData: method, url", method, url, body);
    try{
        return AxiosMap[method](url, body);
    } catch(e) {
        console.error(`updateUUIDData: on ${method} ${url}`, e);
        return new Promise((resolve, reject) => {
            reject(`failed to update information`);
        });
    }
}

/**
 * THIS IS UNTESTED, but it's a good example on how to handle the callbackLocation
 * @param {string} callbackLocation 
 * @param {boolean} success 
 * @param {string} message 
 * @param {*} data 
 * @returns 
 */
function awnserCallback(callbackLocation, success, message, data){
    const body = {
        success,
        message,
        data,
    };

    const {method, url} = handleCallbackLocation(callbackLocation);

    try{
        return AxiosMap[method](url, body);
    } catch(e) {
        console.error(`awnserCallback: on ${method} ${url}`, e);
        return new Promise((resolve, reject) => {
            reject(`failed to update information`);
        });
    }
}


/**
 * Call to API or callbackLocation to update the data of a UUID (post:// before the url to use POST method)
 * @param {string} uuid the uuid of the pool
 * @param {string} callbackLocation where to get the pool // this is currently useless
 */
function getPoll(uuid, callbackLocation){
    return new Promise((resolve, reject) => {
        QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/pollingservice?uuid=${uuid}`)
        .then((response) => {
            if(DEBUG) console.log("getPoll response", response);
            const poolData = response.data // the 'get' gets the pool data only //                                                                  //const [email, action, broadcast] = poolData.split("&&&");
            const pollObj = JSON.parse(poolData); // We know the data is a JSON/Dictionary object 
            if(DEBUG) console.log("getPoll obj", pollObj);    
            resolve(pollObj);
        })
        .catch((error) => {
            console.error("getPoll", error);
            reject(error);
        });
    });

}


function signing(email, actions, { broadcast }) {
    return new Promise((resolve, reject) => {
        MK.signTransaction(email, actions, { broadcast })
        .then((signResponse) => {
            if(DEBUG) console.log("Metakeep: sign successful", signResponse);
            resolve(signResponse);
        })
        .catch((error) => {
            console.log("Metakeep: send error on account", MK.getEOS(), error);
            reject(error);
        });
    });
}


export default function MetakeepFakeSDK() {
    const [loading, setLoading] = useState(true);
    const [finalMessage, setFinalMessage] = useState("");

    

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const a = urlParams.get('a'); // Base 64 Encoded {email, action, broadcast}
        const c = urlParams.get('c'); // callbackLocation
        const u = urlParams.get('u'); // uuid

        if(a){
            // base 64, decode it and act on it
            let decoded = decodeBase64(a);
            let {email, data, broadcast} = JSON.parse(decoded);

            if(DEBUG) console.log("decoded[1st]",decoded);
            if(DEBUG) console.log("decoded[2nd]",{email, data, broadcast});

            broadcast = String(broadcast).toLowerCase() === "true";
            
            signing(email, [data], { broadcast })
            .then((signResponse)=>{
                if(DEBUG) console.log("from-base64: response", signResponse);

                if(!broadcast) { // GO to our backend, could potentionally post into the callbackLocation
                    QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/eos/send`, {
                        from: data.data.from,
                        to: data.data.to,
                        to_username: null, // wont email the receiver this way
                        quantity: data.data.quantity,
                        memo: "Transfer",
                        signature: signResponse.unpackedTransaction,
                    }, {
                        withCredentials: true
                    })
                    .then((response) => {
                        broadcast = true;
                        const message = `Transaction signed${broadcast ? " and broadcasted" : ''}`;
                        const url = `https://explorer-test.telos.net/transaction/${response.data}`;
                        if(DEBUG) console.log("from-base64: after post", response);
                        if(DEBUG) console.log("from-base64: url", url);

                        setFinalMessage(`${message}\nYou can now close this window\nReturn back to the app`);
                    })
                    .catch((error) => {
                        console.error("from-base64: post", error);
                        setFinalMessage("An error occurred\n"+error.message+"\nPlease contact QUDO if you believe this is an error");
                    })
                } else {
                    const message = `Transaction signed${broadcast ? " and broadcasted" : ''}`;
                    const url = `https://explorer-test.telos.net/transaction/${signResponse.transactionId}`;
                    console.log(url);
                    setFinalMessage(`${message}\nYou can now close this window\nReturn back to the app`);
                }
            })
            .catch((error)=>{
                setFinalMessage("An error occurred\n"+error.message+"\nPlease contact QUDO if you believe this is an error");
            }).finally(()=>{
                setLoading(false);
            });

            return;
        }

        if(u){
            // get the pool and act on it
            getPoll(u, c)
            .then((pollObj) => {
                const email      = pollObj.email;
                const broadcast  = String(pollObj.broadcast).toLowerCase() === "true";
                const newUUID    = pollObj.newUUID;
                const action     = pollObj.action;

                signing(email, [action], { broadcast })
                .then((response) => {
                    if(DEBUG) console.log("from-pooling: response", response);
                    updateUUIDData({old_uuid: u, new_uuid: newUUID, data: response, callbackLocation: c})
                    .then((updateResponse) => {
                        if(DEBUG) console.log("from-pooling updateUUIDData response:", updateResponse);
                        setFinalMessage("Transaction signed and broadcasted\nYou can now close this window\nReturn back to the app");
                    })
                    .catch((error) => {
                        if(DEBUG) console.error("from-pooling updateUUIDData:", error);
                        setFinalMessage("An error occurred\n"+error.message+"\nPlease contact QUDO if you believe this is an error");
                    });
                })
                .catch((error) => {
                    console.error("from-pooling signing:", error);
                    setFinalMessage("An error occurred\n"+error.message+"\nPlease contact QUDO if you believe this is an error");
                })
            })
            .catch((error) => {
                console.error("from-pooling:", error);
                setFinalMessage("An error occurred\n"+error.message+"\nPlease contact QUDO if you believe this is an error");
            })

            return;
        }


        setFinalMessage("Hey\nSomething is wrong\nPlease contact QUDO if you believe this is an error");
        setLoading(false);

        
    }, []);



    return( <div> {loading ? ( <Loading/> ) : (
<>
    <div style={
        { height: "100vh", width: "100vw", position: "fixed", top: 0, left: 0, backgroundColor: "rgba(0,0,0,0)"
        , display: "flex", justifyContent: "center", alignItems: "center"
    }}>
        <div style={
            { display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "16px"
            , borderWidth:"4px", borderStyle:"solid", borderImage:"linear-gradient(345deg, #FFF 0%, #000 80%, #000 100%) 25" 
            , lineHeight: "1.1", maxWidth: "80vw", paddingBottom: "4px"
        }}>
            {finalMessage.split("\n").map((line, index) => {
                return(<>
                    {index !== 0 && <hr style={{marginTop: 0}}/>}
                    <p key={index}>
                        {line}
                    </p>
                </>)
            })}
        </div>
    </div>
</> 
    )} </div> );
}







/** Unused via pooling, this is a try to responde to the app, but it wasn't working
 * @param {boolean} success true/false
 * @param {string} message message to be displayed / shown
 * @param {*} data // data, usually the response from the action
 * @param {string} uuid // uuid to identify the request
 * @param {string} callbackLocation // custom url to send the response to
 */
// eslint-disable-next-line no-unused-vars
function awnserToQUDOSDK(success, message, data, uuid, callbackLocation) {
    const qudosdkUrl = callbackLocation || "qudosdk://metakeepfakesdk/result";
    const body = JSON.stringify({
        success,
        message,
        data,
        uuid, 
    });

    console.log("Metakeep sending to QUDO SDK", qudosdkUrl, success, message, data, uuid);
    
    QUDOServer.post(qudosdkUrl, body)
}