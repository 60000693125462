import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Modal, Form } from "react-bootstrap";

import {fetchUser} from "../../../../actions/index";
import QUDOServer from "../../../../helpers/QUDOServerConnection.js";
import {MessageModal} from "../../../components/modals/modals.js";

export default function RemoveGame(props)
{
	const dispatch = useDispatch();

    const [toRemove, setToRemove] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmRemovalModal, setConfirmRemovalModal] = useState(false);

    //const [failureModal, setFailureModal] = useState(false);
    //const [successRemovalModal, setSuccessRemovalModal] = useState(false);

    const [update, setUpdate] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    const startConfirmRemovalModal = game => 
    {
        setConfirmRemovalModal(!confirmRemovalModal);
        setToRemove(game);
    }

    const remove = e => 
    {
        e.preventDefault();

        setLoading(true);

        QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/game/delete`, { gamename: toRemove }, { withCredentials: true })
        .then((response) => 
        {
            //setSuccessRemovalModal(!successRemovalModal);
            setModalMessage("Sorry to see you leaving! Feel free to add another game!");
            setUpdate(true);
        })
        .catch((error) => 
        {
            //setFailureModal(!failureModal);
            setModalMessage(error.errorMessage);
        })
        .finally(() => {
            setToRemove("");
            setLoading(false);
            setConfirmRemovalModal(!confirmRemovalModal);
        });
    }

    const modals = (
        <>
            <Modal show={confirmRemovalModal} onHide={() => setConfirmRemovalModal(!confirmRemovalModal)}>
                <Modal.Body className="confirm-modal">
                    <Form onSubmit={event => remove(event)} style={{ padding: "2rem 4rem 0rem" }}>
                        <div className="row confirm-modal-text">
                            <div className="col-12 text-center">
                                Are you sure you want to remove this game?
                            </div>
                        </div>
                        {loading ? (
                            <div className="row my-4">
                                <div className="col-12">
                                    <div className="text-center">
                                        <ClipLoader color="#282725"/>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="row d-flex position-relative mt-4" style={{ margin: "1rem -4rem 0rem" }}>
                                <input
                                    type="button"
                                    value="No!"
                                    className="col-6 confirm-modal-button"
                                    onClick={() => setConfirmRemovalModal(!confirmRemovalModal)}
                                />
                                <input
                                    type="submit"
                                    value="Yes"
                                    className="col-6 confirm-modal-button"
                                />
                            </div>
                        )}
                    </Form>
                </Modal.Body>
            </Modal>
            {/*}
            <Modal show={successRemovalModal} onHide={() => setSuccessRemovalModal(!successRemovalModal)}>
                <Modal.Body className="confirm-modal">
                    <div style={{ padding: "2rem 4rem 0rem" }}>
                        <div className="confirm-modal-text text-center">
                            Sorry to see you leaving! Feel free to add another game!
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {*/}
            {/*}
            <Modal show={failureModal} onHide={() => { update(); setFailureModal(false) }}>
                <Modal.Body className="confirm-modal">
                    <div style={{ padding: "2rem 4rem" }}>
                        <div className="confirm-modal-text text-center">
                            Oops! Some error occurred. Please try again or contact QUDO support.
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {*/}
        </>
    );

    return (
        <div className="row mx-1">
            <div
                className="editgamebtn col-12"
                style={{ backgroundColor: "#f44336" }}
                onClick={() => startConfirmRemovalModal(props.gameAccount.name)}
            >
                <div className="editcovertext">
                    REMOVE GAME
                </div>
            </div>

            {modals}

			<MessageModal
			    show={modalMessage} 
				message={modalMessage}
				hide={() => {
                    if(update) {
                        setRedirect(true);
                        setTimeout(() => dispatch(fetchUser()), 500);
                    }

                    setModalMessage("");
                    setUpdate(false);
                }}
			/>

            {redirect && (
                <Redirect push to={process.env.REACT_APP_GAMEDEV_GAMES}/>
            )}
        </div>
    );
}
