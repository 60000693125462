import React, {useState, useEffect} from 'react';
import moment from "moment";
import {Modal} from "react-bootstrap";

import useWindowSize from "../../../helpers/useWindowSize";
import QUDOServer from "../../../helpers/QUDOServerConnection";

import GameDetailsModal from "./GameDetailsModal";

const fallBack = require("../../../images/fallbackAvatar.png");

const unavailableBanner = require("../../../images/banners/QUDO_TemporarilyUnavailable.png");
const comingSoonBanner = require("../../../images/banners/QUDO_ComingSoon.png");
const alphaBanner = require("../../../images/banners/QUDO_Alpha.png");
const betaBanner = require("../../../images/banners/QUDO_Beta.png");
const newBanner = require("../../../images/banners/QUDO_New.png");

export default function GamedevDetailsModal(props) {
    const windowSize = useWindowSize();

    const [gamedev, setGamedev] = useState(null);
    const [gameDetails, setGameDetails] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
	//const [showFullDescription, setShowFullDescription] = useState(true);

    useEffect(() => {
        if(props.gamedev) {
            QUDOServer.get(`${process.env.REACT_APP_QUDO_SERVER}/api/gamedevinfo?gamedev=${props.gamedev}`)
            .then(response => {
                //console.log(response.data);
                setGamedev(response.data);
            })
            .catch(error => {
                //console.log(error);
                setErrorMessage('This game developer was not found in the QUDO ecosystem.');
            });
        }
    }, [props.gamedev]);

    useEffect(() => {
        if (!gameDetails) {
            document.title = 'QUDO Games';
        }
    }, [gameDetails]);

    /*
	useEffect(() => {
		if(document.getElementById('gamedev_description')) {
			const descriptionHeigth = document.getElementById('gamedev_description').clientHeight;

			if(descriptionHeigth > 350) {
				setShowFullDescription(false);
			} else {
				setShowFullDescription(true);
			}
		}
	}, [gamedev]);
    */

    function cleanOnHide() {
        props.setShow(false);

        setGamedev(null);
        setErrorMessage(null);
        //setShowFullDescription(true);
    }

    return (
        <>
        <Modal show={props.show} onHide={cleanOnHide}>
			<Modal.Body
				id="gamedetails-modal"
				style={{
                    opacity: 1,
                    justifyContent: 'initial',
					paddingTop: '3rem',
					paddingBottom: '1rem',
					paddingLeft: '2.5%',
					paddingRight: '2.5%'
				}}
			>
                <Modal.Header
                    closeButton
                    className='modal-header-position'
                />

				<div className="row with-scroll">
					{windowSize && windowSize.width < 768 ? (
						<div className="col-12 mb-4">
							<div className="text-center modal-title-big m-0">
                                {gamedev ? (
                                    gamedev.display_name ? gamedev.display_name : gamedev.username
                                    //gamedev.display_name ? gamedev.display_name + ' (aka ' + gamedev.username + ')' : gamedev.username
                                ) : props.gamedev}
							</div>
						</div>
					) : null}

					<div className={`col-12 col-md-4 text-center`}>
                        <img
                            src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygamedev?gamedev=${props.gamedev}&rand=${Date.now()}`}
                            onError={i => i.target.src = fallBack}
                            className="details-modal-image img-fluid mb-4"
                            style={{maxHeight: '250px'}}
                            alt="Game Developer Banner"
                        />
					</div>

					<div className="col-12 col-md-8">
						{windowSize && windowSize.width >= 768 ? (
							<div className="modal-title-big m-0">
                                {gamedev ? (
                                    gamedev.display_name ? gamedev.display_name : gamedev.username
                                    //gamedev.display_name ? gamedev.display_name + ' (aka ' + gamedev.username + ')' : gamedev.username
                                ) : props.gamedev}
							</div>
						) : null}

                        {errorMessage &&
						<div className={`edit-screen-text-area mt-2`}>
                            {errorMessage}
						</div>
                        }

                        <div className="mb-4">
                            {gamedev && gamedev.dateJoined &&
                            <>
                                <div className={`section-title mt-4 mb-1`}>
                                    Joined QUDO on:
                                </div>
                                <div className={`edit-screen-text-area`}>
                                    {moment(gamedev.dateJoined).format("MMMM DD, YYYY")}
                                </div>
                            </>
                            }

                            {gamedev && gamedev.website &&
                            <>
                                <div className={`section-title mt-4 mb-1`}>
                                    Website
                                </div>
                                <a
                                    style={{ color: "black" }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    //href={`http://${gamedev.website}`}
                                    href={gamedev.website}
                                >
                                    {gamedev.website}
                                </a>
                            </>
                            }
                        </div>
                    </div>

                    {gamedev && gamedev.description &&
					<div className="col-12 mb-4">
						<div className="section-title mb-1">
							Description
						</div>
						<div id="gamedev_description" className={`edit-screen-text-area`}>
                            {gamedev.description}
						</div>
                        {/*}
						<div id="gamedev_description" className={`edit-screen-text-area`}>
							{showFullDescription ? gamedev.description : String(gamedev.description).slice(0, 250) + '...'}
						</div>
                        {*/}

						{/*
                        !showFullDescription && (
							<div className="text-center">
								<div
									className="mb-2 enable-click section-title"
									style={{display: 'inline-block'}}
									onClick={() => setShowFullDescription(true)}
								>
									Read more...
								</div>
							</div>
						)
                        */}
					</div>
                    }

                    {gamedev && gamedev.games && gamedev.games.length > 0 &&
					<div className="col-12 my-2">
						<div className="section-title mb-1">
							Games
						</div>
                        <div className="row">
                        {gamedev.games.map(i => {
                            var label = null;
                            var altText = '';
                            var customStyle = {};

                            if(i.comingSoon) {
                                label = comingSoonBanner;
                                altText = 'Game coming soon...';
                                customStyle = {maxWidth: '300px'};
                            } else {
                                if(i.gameState === 'unavailable') {
                                    label = unavailableBanner;
                                    altText = 'Game is temporarily unavailable';
                                } else if(i.gameState === 'alpha') {
                                    label = alphaBanner;
                                    altText = 'Game in alpha state';
                                } else if(i.gameState === 'beta') {
                                    label = betaBanner;
                                    altText = 'Game in beta state';
                                } else if(i.gameState === 'stable') {
                                    if(i.newGame) {
                                        label = newBanner;
                                        altText = 'New game';
                                    }
                                }
                            }

                            return (
                                <div
                                    key={i.name}
                                    className="col-12 col-md-6 col-lg-4 col-xl-3 mt-2 position-relative enable-click"
                                    onClick={() => {
                                        //console.log(i);
                                        setGameDetails(i.name);
                                    }}
                                >
                                    <div className="tw-image-container">
                                        <img
                                            alt={i.display_name ? i.display_name : i.name}
                                            src={`${process.env.REACT_APP_QUDO_SERVER}/api/images/getbygame?gamename=${i.name}`}
                                            onError={i => i.target.src=fallBack}
                                            className="game-website-tw-image"
                                        />

                                        {label && (
                                            <img
                                                src={label}
                                                alt={altText}
                                                className="game-state-banner"
                                                style={customStyle}
                                            />
                                        )}
                                    </div>
                                    
                                    <div className="games-textblock text-left">
                                        <div className="games-text-bg">
                                            {i.display_name ? i.display_name : i.name}
                                        </div>
                                        <div className="games-text-sm">
                                            {gamedev ? (gamedev.display_name ? gamedev.display_name : gamedev.username) : props.gamedev}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        </div>
                    </div>
                    }
                </div>
            </Modal.Body>
        </Modal>

        <GameDetailsModal
            show={!!gameDetails}
            setShow={setGameDetails}
            game={gameDetails}
        />
        </>
    );
}
