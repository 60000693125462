import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { DisplayNameInput, GameNameInput, TextAreaInput } from '../../../components/forms/text';
import { GoBackArrow } from '../../../navigation/arrows';
import regexes from '../../../../helpers/validationRegexes'
import { ButtonInput } from '../../../components/forms/buttons';
import QUDOServer from '../../../../helpers/QUDOServerConnection';
import { MessageModal } from '../../../components/modals/modals';
//import StakesComponent from '../stakes/stakesComponent';
//import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function NewApplication(props)
{
    const history = useHistory();

    const [displayName, setDisplayName] = useState('');
    const [tempName, setTempName] = useState('');
    const [description, setDescription] = useState('');

    const [valid, setValid] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const [error, setError] = useState(false);

    const resetAllEditing = () =>
    {
        setTempName('');
        setDescription('');
        setDisplayName('');
    }

    const createApplication = () =>
    {
        if(valid) 
        {
            QUDOServer.post(`${process.env.REACT_APP_QUDO_SERVER}/application/create`,
            {
                application: tempName,
                description: description,
                display_name: displayName
            },
            {
                withCredentials: true,
            }
            )
            .then(resp => 
            {
                setError(false);
                setShowModal(true);
                setModalMessage('Success');
            })
            .catch((error) => 
            {
                setError(true);
                setShowModal(true);
                setModalMessage(error.errorMessage);
            });
        }
    }

    const buttons = (
		<div className="row">
			<div className="col-6 pr-0">
				<ButtonInput 
					label="Cancel"
					trigger
					set={() => {
						resetAllEditing();
					}}
					className="editgamebtn w-100"
					style={{border: '1px solid #b2b2b2', backgroundColor: 'white', color: '#f44336'}}
				/>
			</div>
			<div className="col-6 pl-0">
				<ButtonInput
					label="Save"
					set={createApplication}
					trigger
					className="editgamebtn w-100"
					style={{border: '1px solid #b2b2b2', backgroundColor: 'white', color: '#00cc69'}}
                    disabled={!valid}
				/>
			</div>
		</div>
	)

    const onModalHideHandler = () =>
    {
        setShowModal(false);

        if (!error)
        {
            props.fetchNewInfo();
            history.goBack();
        }
    }

    return (
        <>
            <div className="col-12 mb-3">
                <GoBackArrow customClass='col-custom align-self-center mt-4 pt-1'/>
            </div>
            <div className="col-12 mb-2">
                <h2>Add Application</h2>
            </div>
            <div className="row col-12">
                <div className="col-md-4 col-12">
                    <Form>
                        <GameNameInput 
                            set={setTempName} 
                            pattern={regexes.gameName}
                            placeholder="Unique ID"
                            valid={valid}
                            // errorMessage={errorMessage}
                            setValid={setValid}
                        />
                        <DisplayNameInput
                            type="game"
                            value={displayName}
                            set={setDisplayName}
                            placeholder="Display name (optional)"
                        />
                        <TextAreaInput
                            placeholder="Description"
                            value={description}
                            set={setDescription}
                            className={"col-12 input-field-custom-tall"}
                            maxLength={1000}
                        />
                    </Form>	
                    {buttons}
                </div>
                <div className="col-md-5 col-12"></div>
            </div>
            <MessageModal
                show={showModal} 
                message={modalMessage}
                hide={() => onModalHideHandler()}
            />
        </>
    );
}
